import React, { useState, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import DownArrowSvg from "../../../../assets/DynamicIcons/DownArrowSvg";
import UpArrowSvg from "../../../../assets/DynamicIcons/UpArrowSvg";
import DroughtImg from "../../../../assets/IndexIcons/Drought.png";
import HeatStressImg from "../../../../assets/IndexIcons/Heat Stress.png";
import ExtremePrecipitationImg from "../../../../assets/IndexIcons/Extreme Precipitation.png";
import WaterStressImg from "../../../../assets/IndexIcons/Water stress.png";
import RiverineFloodingImg from "../../../../assets/IndexIcons/RiverineFlooding.png";
import FlashFloodingImg from "../../../../assets/IndexIcons/PluvialFlooding.png";
import { roundNumber } from "../../../../services/Helpers/displayTextHelper";
import {
	getRiskBorderColorWithCategory,
	getRiskCategory,
	getRiskColorWithCategory,
} from "../../../../services/Helpers/climateRiskHelper";
import { RISK_CATEGORY } from "../../../../constants/RiskCategory";

const PortfolioTable = ({ tableData, isFinancial = false }) => {
	const [showPercentage, setShowPercentage] = useState(true);

	const isFutureData = useMemo(() => {
		return (
			tableData.length > 0 &&
			tableData[0].data &&
			typeof tableData[0].data === "object"
		);
	}, [tableData]);

	const processedData = useMemo(() => {
		if (!tableData || tableData.length === 0) return {};
		const riskCategories = [
			RISK_CATEGORY.Low,
			RISK_CATEGORY.Medium,
			RISK_CATEGORY.MediumHigh,
			RISK_CATEGORY.High,
			RISK_CATEGORY.VeryHigh,
		];
		const initialRiskData = riskCategories.reduce((acc, cat) => {
			acc[cat] = {
				Drought: 0,
				"Heat Stress": 0,
				"Extreme Precipitation": 0,
				"Water Stress": 0,
				"Fluvial Flood": 0,
				"Pluvial Flood": 0,
				//"Coastal Flood": 0,
			};
			return acc;
		}, {});
		if (isFutureData) {
			tableData.forEach((asset) => {
				Object.entries(asset.data).forEach(([riskType, values]) => {
					values.forEach((value) => {
						const category = getRiskCategory(value);
						initialRiskData[category][riskType]++;
					});
				});
			});

			return initialRiskData;
		} else {
			tableData.forEach((row) => {
				Object.entries(row).forEach(([key, value]) => {
					if (
						key !== "asset" &&
						key !== "latlon" &&
						key !== "location"
					) {
						const category = getRiskCategory(value);
						initialRiskData[category][key]++;
					}
				});
			});

			return initialRiskData;
		}
	}, [tableData]);

	const columns = useMemo(() => {
		const baseColumns = [
			{
				Header: "Risk Category",
				accessor: "riskCategory",
				Cell: ({ value }) => {
					return (
						<div
							className={`border-l-8 ${getRiskBorderColorWithCategory(
								value
							)}`}
						>
							<div className="px-6 py-4">{value}</div>
						</div>
					);
				},
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img src={DroughtImg} width={25} alt="draughtIcon" />
						<span className="ml-1">Drought</span>
					</div>
				),
				accessor: "Drought",
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={HeatStressImg}
							width={25}
							alt="heatStressIcon"
						/>
						<span className="ml-1">Heat Stress</span>
					</div>
				),
				accessor: "Heat Stress",
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={WaterStressImg}
							width={23}
							alt="waterStressIcon"
						/>
						<span className="ml-1">Water Stress</span>
					</div>
				),
				accessor: "Water Stress",
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={RiverineFloodingImg}
							width={23}
							alt="riverineFloodIcon"
						/>
						<span className="ml-1">Fluvial Flood</span>
					</div>
				),
				accessor: "Fluvial Flood",
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={FlashFloodingImg}
							width={25}
							alt="flashFloodIcon"
						/>
						<span className="ml-1">Pluvial Flood</span>
					</div>
				),
				accessor: "Pluvial Flood",
			},
		];
		if (!isFinancial) {
			baseColumns.splice(3, 0, {
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={ExtremePrecipitationImg}
							width={23}
							alt="extremePrecipitationIcon"
						/>
						<div className="ml-1">
							<div>Extreme</div>
							<div>Precipitation</div>
						</div>
					</div>
				),
				accessor: "Extreme Precipitation",
			});
		}
		return baseColumns;
	}, []);

	const finalTableData = useMemo(() => {
		const totalEntries = isFutureData
			? tableData.length * tableData[0].years.length
			: tableData.length;
		return Object.entries(processedData).map(([riskCategory, values]) => {
			const row = { riskCategory };
			Object.keys(values).forEach((riskType) => {
				const count = values[riskType];
				row[riskType] = showPercentage
					? `${roundNumber((count / totalEntries) * 100, 2)}%`
					: count;
			});
			return row;
		});
	}, [showPercentage, processedData, tableData.length]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data: finalTableData }, useSortBy);

	return (
		<div>
			<div className="flex justify-end mb-3">
				<label className="flex items-center cursor-pointer">
					<span className="mr-2">#</span>
					<input
						type="checkbox"
						className="toggle border-blue-500 bg-blue-500 hover:bg-blue-700"
						checked={showPercentage}
						onChange={() => setShowPercentage((prev) => !prev)}
					/>
					<span className="ml-2">%</span>
				</label>
			</div>
			{finalTableData.length ? (
				<div className="relative overflow-x-auto overflow-y-auto max-h-96 rounded-md border border-gray-300 w-full show-scrollbar">
					<table
						{...getTableProps()}
						className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
					>
						<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 sticky top-0">
							{headerGroups.map((headerGroup) => (
								<tr
									{...headerGroup.getHeaderGroupProps()}
									className="border-b-[1px] border-gray-300"
								>
									{headerGroup.headers.map((column) => (
										<th
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
											className="px-4 py-3 border-l border-gray-300 select-none relative w-[14.2857%]"
											align="center"
										>
											<div className="flex justify-center items-center">
												{column.render("Header")}
												{column.isSorted && (
													<span
														className={`ml-2 absolute right-1 ${
															isFinancial
																? "top-3"
																: "top-3 pt-[4px]"
														}`}
													>
														{column.isSortedDesc ? (
															<DownArrowSvg />
														) : (
															<UpArrowSvg />
														)}
													</span>
												)}
											</div>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										className="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
										align="center"
									>
										{row.cells.map((cell) => (
											<td
												{...cell.getCellProps()}
												className={` ${getRiskColorWithCategory(
													cell.value !== "0%" &&
														cell.value !== 0
														? row.original
																.riskCategory !==
														  cell.value
															? row.original
																	.riskCategory
															: ""
														: cell.value
												)} border-l border-gray-300 text-black`}
											>
												{cell.render("Cell")}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : (
				<div className="text-neutral-500 text-lg">No data found</div>
			)}
		</div>
	);
};

export default PortfolioTable;
