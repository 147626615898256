export const actionOptions = [
	{
		label: "1.Rain-H",
		value: 0,
	},
	{
		label: "2.Tmin-H",
		value: 1,
	},
	{
		label: "3.Tmax-H",
		value: 2,
	},
	{
		label: "4.Pet-H",
		value: 3,
	},
	{
		label: "5.SPEI-H",
		value: 4,
	},
	{
		label: "6.EHD-H",
		value: 5,
	},
	{
		label: "7.HI-H",
		value: 6,
	},
	{
		label: "8.HS-H",
		value: 7,
	},
	{
		label: "9.EP-H",
		value: 8,
	},
	{
		label: "10.WS-H",
		value: 9,
	},
	{
		label: "11.PET245-F",
		value: 10,
	},
	{
		label: "12.PET585-F",
		value: 11,
	},
	{
		label: "13.SPEI245-F",
		value: 12,
	},
	{
		label: "14.SPEI585-F",
		value: 13,
	},
	{
		label: "15.HS245-F",
		value: 14,
	},
	{
		label: "16.HS585-F",
		value: 15,
	},
	{
		label: "17.EP245-F",
		value: 16,
	},
	{
		label: "18.EP585-F",
		value: 17,
	},
	{
		label: "19.WS-F",
		value: 18,
	},
];
