import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import very_low_risk from "../../assets/MapMarkerIcons/very_low.svg";
import low_risk from "../../assets/MapMarkerIcons/low.svg";
import moderate_risk from "../../assets/MapMarkerIcons/moderate.svg";
import high_risk from "../../assets/MapMarkerIcons/high.svg";
import very_high_risk from "../../assets/MapMarkerIcons/very_high.svg";
import { RISK_CATEGORY } from "../../constants/RiskCategory";

const Map = ({ data }) => {
	const getIcon = (risk) => {
		return new L.Icon({
			iconUrl:
				risk === RISK_CATEGORY.Low
					? very_low_risk
					: risk === RISK_CATEGORY.Medium
					? low_risk
					: risk === RISK_CATEGORY.MediumHigh
					? moderate_risk
					: risk === RISK_CATEGORY.High
					? high_risk
					: very_high_risk,
			iconSize: [30, 41],
			iconAnchor: [15, 31],
		});
	};
	return (
		<MapContainer
			center={[22.5, 78.9629]}
			zoom={4}
			style={{ height: "400px" }}
			className="rounded-md z-0"
			attributionControl={false}
		>
			<TileLayer url="https://www.google.com/maps/vt?lyrs=m@189&gl=in&x={x}&y={y}&z={z}" />
			{data?.length &&
				data.map((point, index) => {
					const { lat, lon, risk, asset } = point;

					return (
						<Marker
							key={index}
							position={[lat, lon]}
							icon={getIcon(risk)}
						>
							<Popup>
								<div>{asset}</div>
								<div>{risk}</div>
							</Popup>
						</Marker>
					);
				})}
		</MapContainer>
	);
};

export default Map;
