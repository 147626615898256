import React, { useContext, useEffect, useState } from "react";
import { getClimateRiskChartData } from "../../../../services/ApiCalls/climateRiskCall";
import LineChart from "../../../../components/Chart/LineChart";
import YearRangePicker from "../../../../components/RangePicker/YearRangePicker";
import { computeTrendline } from "../../../../services/Helpers/chartHelper";
import { LoginContext } from "../../../../LoginProvider/LoginProvider";

const HeatStressChartModal = ({
	isSelected,
	setIsSelected,
	chartData,
	startDate,
	endDate,
	isHistoricChart = true,
	title,
	modalTitle,
	is_585,
}) => {
	const [config, setConfig] = useState([]);
	const [layout, setLayout] = useState({});
	const [yearRange, setYearRange] = useState([]);
	const { userData } = useContext(LoginContext);
	useEffect(() => {
		(async () => {
			const { indexName, latlon } = chartData;
			const result = await getClimateRiskChartData(
				indexName,
				latlon,
				yearRange[0],
				yearRange[1],
				isHistoricChart,
				is_585
			);
			if (result?.status === "success") {
				const data = result.data.data;
				const years = result.data.years;
				const hiDays = data.map((item) => item.a_hi_days);
				const pcDays = data.map((item) => item.c_90pcdays);

				const trendlineHiDays = computeTrendline(years, hiDays);
				const trendlinePcDays = computeTrendline(years, pcDays);

				const configData = [
					{
						x: years,
						y: hiDays,
						type: "scatter",
						mode: "lines+markers",
						name: "Heat Index Days",
						line: { shape: "spline", color: "#1f77b4" },
						marker: { size: 6 },
					},
					{
						x: years,
						y: trendlineHiDays,
						type: "scatter",
						mode: "lines",
						name: "Heat Index Days Trendline",
						line: { dash: "dash", color: "#1f77b4" },
					},
					{
						x: years,
						y: pcDays,
						type: "scatter",
						mode: "lines+markers",
						name: "Extreme Heat Days (90th percentile)",
						line: { shape: "spline", color: "#ff7f0e" },
						marker: { size: 6 },
					},
					{
						x: years,
						y: trendlinePcDays,
						type: "scatter",
						mode: "lines",
						name: "Extreme Heat Days Trendline",
						line: { dash: "dash", color: "#ff7f0e" },
					},
				];
				setConfig(configData);
				const layoutData = {
					xaxis: {
						title: "Years",
					},
					yaxis: {
						title: "Days/year",
					},
					title: {
						text: title,
						font: {
							size: 18,
						},
					},
					showlegend: true,
					hovermode: "closest",
				};
				setLayout(layoutData);
			}
		})();
	}, [yearRange]);

	const onChange = (dates, dateStrings) => {
		setYearRange(dateStrings);
	};

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal text-black"
			>
				<div className="relative p-4 w-full max-w-5xl h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								{modalTitle}
							</h3>
							<button
								type="button"
								className="z-30 absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>
						<div className="flex w-full flex-row-reverse p-2 mt-4">
							<YearRangePicker
								onChange={onChange}
								startDate={startDate}
								endDate={endDate}
							/>
						</div>
						<div className="overflow-hidden">
							<div className="p-6 w-full -mt-12">
								<LineChart
									data={config}
									layout={layout}
									chartName={`${title}_${userData?.email}`}
								/>
							</div>
						</div>
						<div className="text-sm px-6 pb-4">
							<div className="flex gap-3">
								<p className="font-medium">Heat Index Days:</p>
								<p>
									Days when a combination of heat and humidity
									creates high perceived temperature stress.
								</p>
							</div>
							<div className="flex gap-3">
								<p className="font-medium">
									Extreme Heat Days (90th percentile):
								</p>
								<p>
									Days when the maximum temperature exceeds
									the 90th percentile of historical daily
									highs.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HeatStressChartModal;
