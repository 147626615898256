import React from "react";
import { useTable, useSortBy } from "react-table";
import DownArrowSvg from "../../../../assets/DynamicIcons/DownArrowSvg";
import UpArrowSvg from "../../../../assets/DynamicIcons/UpArrowSvg";
import {
	formatNumberWithCommas,
	roundNumber,
} from "../../../../services/Helpers/displayTextHelper";

const ClimateFinancialImpactTable = ({ tableData, showPercentage }) => {
	const data = React.useMemo(() => tableData, [tableData]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Asset",
				accessor: "Asset Name",
			},
			{
				Header: "Asset type",
				accessor: "Asset type",
				Cell: ({ value }) => {
					return value !== undefined ? <>{value}</> : <>{"-"}</>;
				},
			},
			{
				Header: "Industry",
				accessor: "Industry",
				Cell: ({ value }) => {
					return value !== undefined ? <>{value}</> : <>{"-"}</>;
				},
			},
			{
				Header: "Exposure (INR)",
				accessor: "Exposure (INR)",
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{formatNumberWithCommas(roundNumber(value, 2))}</>
					) : (
						"-"
					);
				},
			},
			{
				Header: `${
					showPercentage
						? "Asset Damage Ratio (%)"
						: "Asset Damage Value (INR)"
				}`,
				accessor: `${
					showPercentage
						? "Asset Damage Ratio (%)"
						: "Asset Damage Value (INR)"
				}`,
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{formatNumberWithCommas(roundNumber(value, 2))}</>
					) : (
						"-"
					);
				},
			},
			{
				Header: `${
					showPercentage
						? "Business Interruption Ratio (%)"
						: "Business Interruption Value (INR)"
				}`,
				accessor: `${
					showPercentage
						? "Business Interruption Ratio (%)"
						: "Business Interruption Value (INR)"
				}`,
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{formatNumberWithCommas(roundNumber(value, 2))}</>
					) : (
						"-"
					);
				},
			},
			{
				Header: `${
					showPercentage
						? "Climate Annual Expected Losses (%)"
						: "Climate Annual Expected Losses (INR)"
				}`,
				accessor: `${
					showPercentage
						? "Climate Annual Expected Losses (%)"
						: "Climate Annual Expected Losses (INR)"
				}`,
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{formatNumberWithCommas(roundNumber(value, 2))}</>
					) : (
						"-"
					);
				},
			},
		],
		[tableData, showPercentage]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data }, useSortBy);

	return (
		<>
			{tableData.length ? (
				<div className="relative overflow-x-auto overflow-y-auto max-h-96 rounded-md border border-gray-300 w-full show-scrollbar">
					<table
						{...getTableProps()}
						className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
					>
						<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
							{headerGroups.map((headerGroup) => (
								<tr
									{...headerGroup.getHeaderGroupProps()}
									className="border-b-[1px] border-gray-300"
								>
									{headerGroup.headers.map((column) => (
										<th
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
											className="px-4 py-3 border-l border-gray-300 select-none relative w-[14.2857%]"
											align="center"
										>
											<div className="flex justify-center items-center">
												{column.render("Header")}
												{column.isSorted && (
													<span className="ml-2 absolute right-0 top-4">
														{column.isSortedDesc ? (
															<DownArrowSvg />
														) : (
															<UpArrowSvg />
														)}
													</span>
												)}
											</div>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										className="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
										align="center"
									>
										{row.cells.map((cell) => (
											<td
												{...cell.getCellProps()}
												className={`px-6 py-4 border-l border-gray-300 text-black ${
													row.original[
														"Asset Name"
													] === "Portfolio"
														? "font-medium"
														: ""
												}`}
											>
												{cell.render("Cell")}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : (
				<div className="text-neutral-500 text-lg">No data found</div>
			)}
		</>
	);
};

export default ClimateFinancialImpactTable;
