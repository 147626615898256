export const getLocationsFromAssets = (assets, maxRisks = undefined) => {
	return assets.map((asset, i) => {
		return {
			lat: asset?.Lat,
			lon: asset?.Long,
			asset: asset?.["Asset Name"],
			risk: maxRisks ? maxRisks[i] : "High",
			location: asset?.Location,
		};
	});
};
