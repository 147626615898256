import { Route, Routes, HashRouter, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { LoginProvider } from "./LoginProvider/LoginProvider";
import Admin from "./pages/Admin/Admin";
import UserManagement from "./pages/Admin/UserManagement";
import User from "./pages/User/User";
import Assessment from "./pages/User/Assessment";
import ProcessPerils from "./pages/Admin/ProcessPerils";

function Paths() {
	return (
		<LoginProvider>
			<HashRouter>
				<Routes>
					<Route path="/" element={<LoginPage />} />
					<Route path="/admin" element={<Admin />}>
						<Route
							index
							element={<Navigate to="userManagement" />}
						/>
						<Route
							path="userManagement"
							element={<UserManagement />}
						/>
						<Route
							path="processPerils"
							element={<ProcessPerils />}
						/>
					</Route>
					<Route path="/user" element={<User />}>
						<Route
							index
							element={<Navigate to="assessmentPage" />}
						/>
						<Route path="assessmentPage" element={<Assessment />} />
					</Route>
				</Routes>
			</HashRouter>
		</LoginProvider>
	);
}

export default Paths;
