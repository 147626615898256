import React, { useState, useEffect } from "react";
import GradientButtonHeader from "../../components/Button/GradientButtonHeader";
import Loader from "../../components/Loading/Loader";
import ErrorModal from "../../components/Modal/ErrorModal";
import CreateUserModal from "./Components/Modal/CreateUserModal";
import SuccessModal from "../../components/Modal/SuccessModal";
import UsersCard from "./Components/UsersCard";
import EditUserModal from "./Components/Modal/EditUserModal";
import DeleteUserModal from "./Components/Modal/DeleteUserModal";
import { captureException } from "@sentry/react";
import { deleteUser, getAllUsers } from "../../services/ApiCalls/userCall";

const UserManagement = () => {
	const [selected, setSelected] = useState(false);
	const [item, setItem] = useState({});
	const [selectedEditModal, setSelectedEditModal] = useState(false);
	const [selectedDeleteUserModal, setSelectedDeleteUserModal] =
		useState(false);
	const [loading, setLoading] = useState(true);
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [users, setUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({});
	const [reloadData, setReloadData] = useState(false);
	const [bookmark, setBookmark] = useState(null);

	const handleDeleteUser = async () => {
		try {
			setLoading(true);
			setSelectedDeleteUserModal(false);
			const result = await deleteUser(item.email);
			if (result?.status === "success") {
				setSuccessmsg({
					status: true,
					msg: "User deleted Successfully!",
				});
				setUsers((prev) =>
					prev.filter((user) => user.email !== item.email)
				);
			} else {
				setErrormsg({
					status: true,
					msg: "User does not exists...",
				});
			}
			setSelectedDeleteUserModal(false);
			setLoading(false);
		} catch (error) {
			captureException(error);
			setErrormsg({
				status: true,
				msg: "User does not exists...",
			});
			setSelectedDeleteUserModal(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		(async () => {
			setReloadData(false);
			let result = await getAllUsers(
				process.env.REACT_APP_SPYDRA_RESULT_PAGE_SIZE
			);
			if (result.status === "success") {
				const usersData = result.data.filter((user) => user.role !== 0);
				setUsers(usersData);
			}
			setLoading(false);
		})();
	}, [reloadData]);

	return (
		<div className={`mb-8 -mx-4 ${loading ? "relative h-[80vh]" : ""}`}>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			{loading && <Loader />}
			{selected && (
				<CreateUserModal
					selected={selected}
					setSelected={setSelected}
					setErrormsg={setErrormsg}
					setSuccessmsg={setSuccessmsg}
					setReloadData={setReloadData}
				/>
			)}
			{selectedEditModal && (
				<EditUserModal
					selectedEditModal={selectedEditModal}
					setSelectedEditModal={setSelectedEditModal}
					setErrormsg={setErrormsg}
					setSuccessmsg={setSuccessmsg}
					user={currentUser}
					users={users}
					setUsers={setUsers}
				/>
			)}
			{selectedDeleteUserModal && (
				<DeleteUserModal
					isDeleteUser={selectedDeleteUserModal}
					handleDeleteUser={handleDeleteUser}
					setIsDeleteUser={setSelectedDeleteUserModal}
				/>
			)}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-8 md:pl-1 lg:pl-2 xl:pl-2">
					<h2 className="text-left text-2xl lg:mb-8 xl:mb-10 xl:mt-1">
						User Management Dashboard
					</h2>
					<GradientButtonHeader
						onClick={() => {
							setSelected(true);
						}}
						className="ml-auto text-white mb-8"
					>
						+ Create User
					</GradientButtonHeader>
				</div>
				{users?.length !== 0 ? (
					<>
						<div className="px-1 mt-8 py-6 gap-4 md:gap-0 md:justify-around flex font-bold border-y border-darkmode-500 text-center">
							<p className="w-1/3 md:w-2/12 my-auto">Email</p>
							<p className="w-1/3 md:w-5/12 my-auto ">
								Enable/Disable
							</p>
							<p className="w-1/3 md:w-5/12 my-auto ">Delete</p>
						</div>
						<div className="my-5 flex flex-col gap-3">
							{users?.map((item) => (
								<>
									<UsersCard
										key={item.id}
										data={item}
										setCurrentUser={setCurrentUser}
										setErrormsg={setErrormsg}
										setSuccessmsg={setSuccessmsg}
										setLoading={setLoading}
										setSelectedDeleteUserModal={
											setSelectedDeleteUserModal
										}
										setItem={setItem}
										isUserManagementPage={true}
									/>
								</>
							))}
							{bookmark && (
								<div className="text-center">
									<button
										className="py-2 px-8 mt-3 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
										// onClick={handleLoadMore}
									>
										Load More
									</button>
								</div>
							)}
						</div>
					</>
				) : (
					<div className="relative h-screen flex justify-center">
						<div className="text-[#64748B] text-xl text-center mt-3 absolute top-40">
							<p>No Users available.</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserManagement;
