import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import { getFinancialImpactData } from "../../../services/ApiCalls/climateRiskCall";
import ClimateFinancialImpactTable from "./Tables/ClimateFinancialImpactTable";
import { sortArrayByProperty } from "../../../services/Helpers/sortItemsHelper";
import {
	climateHazardOptions,
	timePeriodOptions,
} from "../../../constants/climatefinancialImpact";
import Selection from "../../../components/Inputs/Selection";

const ClimateFinancialImpactProjected = ({
	setLoading,
	projectedTableScenario,
}) => {
	const [tableData, setTableData] = useState([]);
	const [timePeriod, setTimePeriod] = useState(timePeriodOptions[0].value);
	const [climateHazard, setClimateHazard] = useState(
		climateHazardOptions[0].value
	);
	const [timePeriodOutline, setTimePeriodOutline] = useState(false);
	const [climateHazardOutline, setClimateHazardOutline] = useState(false);
	const [showPercentage, setShowPercentage] = useState(true);
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		(async () => {
			setLoading(true);

			const projectedClimateRiskData = await getFinancialImpactData(
				userData?.id,
				projectedTableScenario,
				timePeriod,
				climateHazard
			);
			if (
				projectedClimateRiskData?.status === "success" &&
				projectedClimateRiskData?.data?.length
			) {
				let data = sortArrayByProperty(
					projectedClimateRiskData.data.map((item) => item.data),
					"Asset Name"
				);

				data = [data.pop(), ...data];
				setTableData(data);
			} else {
				setTableData([]);
			}
			setLoading(false);
		})();
	}, [userData, projectedTableScenario, timePeriod, climateHazard]);

	return (
		<div>
			<section className="text-gray-600  px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="text-lg font-bold my-1">
							Climate Financial Impact
						</div>
						<div className="flex justify-between">
							<div className="w-1/5">
								<label className="label font-medium">
									<span>Time Period</span>
								</label>
								<div
									className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
										timePeriodOutline
											? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
											: ""
									}`}
								>
									<Selection
										customClassName="z-20"
										onChange={(value) => {
											setTimePeriod(value.value);
										}}
										value={timePeriod}
										placeholder="Please&nbsp;select"
										options={timePeriodOptions}
										focus={setTimePeriodOutline}
									></Selection>
								</div>
							</div>
							<div className="w-1/5">
								<label className="label font-medium">
									<span>Climate Hazard</span>
								</label>
								<div
									className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
										climateHazardOutline
											? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
											: ""
									}`}
								>
									<Selection
										customClassName="z-20"
										onChange={(value) => {
											setClimateHazard(value.value);
										}}
										value={climateHazard}
										placeholder="Please&nbsp;select"
										options={climateHazardOptions}
										focus={setClimateHazardOutline}
									></Selection>
								</div>
							</div>
							<div className="w-1/5 flex justify-end items-center">
								<label className="flex items-center cursor-pointer">
									<span className="mr-2">&#8377;</span>
									<input
										type="checkbox"
										className="toggle border-blue-500 bg-blue-500 hover:bg-blue-700"
										checked={showPercentage}
										onChange={() =>
											setShowPercentage((prev) => !prev)
										}
									/>
									<span className="ml-2">%</span>
								</label>
							</div>
						</div>
						<div className="overflow-x-auto">
							{tableData?.length ? (
								<ClimateFinancialImpactTable
									tableData={tableData}
									showPercentage={showPercentage}
								/>
							) : (
								<div className="text-neutral-500 text-lg">
									No data found
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ClimateFinancialImpactProjected;
