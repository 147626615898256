import React, { useState } from "react";
import CurrentAssessment from "./CurrentAssessment";
import ProjectedAssessment from "./ProjectedAssessment";
import { RISK_CATEGORY } from "../../constants/RiskCategory";
import PerilDefinitionsModal from "./components/Modal/PerilDefinitionsModal";

const Assessment = () => {
	const [isSelected, setIsSelected] = useState(false);

	return (
		<div>
			{isSelected && (
				<PerilDefinitionsModal
					isSelected={isSelected}
					setIsSelected={setIsSelected}
				/>
			)}
			<CurrentAssessment />
			<ProjectedAssessment />
			<div className="text-[10px] fixed left-0 bottom-0 bg-white py-2 px-3 rounded-sm border border-gray-200 z-50">
				<div className="flex flex-row-reverse">
					<span
						className="text-xs text-blue-500 underline cursor-pointer font-semibold"
						onClick={() => setIsSelected(true)}
					>
						Glossary
					</span>
				</div>
			</div>
			<div className="text-[10px] fixed right-0 bottom-0 bg-white p-2 rounded-sm border border-gray-200 z-50">
				<div className="flex text-gray-500 gap-3 items-center ml-1">
					<p className="w-[10%] font-bold">Legend</p>

					<div className="flex flex-row text-[11px] text-black">
						<div className={`bg-low-risk py-1 px-2`}>
							{RISK_CATEGORY.Low}
						</div>
						<div className={`bg-medium-risk py-1 px-2`}>
							{RISK_CATEGORY.Medium}
						</div>
						<div className={`bg-medium-high-risk py-1 px-2`}>
							{RISK_CATEGORY.MediumHigh}
						</div>
						<div className={`bg-high-risk py-1 px-2`}>
							{RISK_CATEGORY.High}
						</div>
						<div className={`bg-very-high-risk py-1 px-2`}>
							{RISK_CATEGORY.VeryHigh}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Assessment;
