import React from "react";
import Plot from "react-plotly.js";
import Loader from "../Loading/Loader";

const LineChart = ({ data, layout, chartName = "chart" }) => {
	return (
		<div>
			{!data?.length && <Loader />}
			<Plot
				className={`w-full ${!data.length ? "invisible" : ""}`}
				data={data}
				layout={layout}
				config={{
					displayModeBar: true,
					scrollZoom: false,
					modeBarButtonsToRemove: [
						"zoom2d",
						"pan2d",
						"select2d",
						"lasso2d",
						"zoomIn2d",
						"zoomOut2d",
						"autoScale2d",
						"resetScale2d",
						"toggleSpikelines",
					],
					modeBarButtonsToAdd: ["toImage"],
					displaylogo: false,
					toImageButtonOptions: {
						filename: chartName,
						width: 800,
						height: 600,
					},
				}}
				style={{ width: "100%", height: "100%" }}
			/>
		</div>
	);
};

export default LineChart;
