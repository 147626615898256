import React, { useContext, useEffect, useState } from "react";
import ProjectedClimateRiskTable from "./Tables/ProjectedClimateRiskTable";
import Map from "../../../components/Map/Map";
import {
	getRiskColor,
	calculateClimateRiskWeightedAverages,
	getMaxRiskCategoriesOfAssets,
	calculateAndAddMaxPerilProjected,
} from "../../../services/Helpers/climateRiskHelper";
import { getLocationsFromAssets } from "../../../services/Helpers/locationHelper";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import { getFutureClimateRiskScore } from "../../../services/ApiCalls/climateRiskCall";
import HeatStressChartModal from "./Modal/HeatStressChartModal";
import ExtremePreciChartModal from "./Modal/ExtremePreciChartModal";

const PhysicalClimateRiskScoreProjected = ({
	setLoading,
	projectedTableScenario,
}) => {
	const [tableData, setTableData] = useState([]);
	const [portfolio, setPortfolio] = useState([]);
	const [locations, setLocations] = useState();
	const [chartModal, setChartModal] = useState(false);
	const [projectedChartData, setProjectedChartData] = useState();
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		(async () => {
			setLoading(true);

			const projectedClimateRiskData = await getFutureClimateRiskScore(
				userData?.id,
				projectedTableScenario
			);
			if (projectedClimateRiskData?.status === "success") {
				let score = projectedClimateRiskData.data;
				if (userData?.assets) {
					const maxRisks = getMaxRiskCategoriesOfAssets(score);
					const locationsData = getLocationsFromAssets(
						userData?.assets,
						maxRisks
					);
					setLocations(locationsData);
				}
				score = calculateAndAddMaxPerilProjected(score);
				setTableData(score);
				const portfoloScore =
					calculateClimateRiskWeightedAverages(score);
				setPortfolio(portfoloScore);
			}
			setLoading(false);
		})();
	}, [userData, projectedTableScenario]);

	return (
		<div>
			{chartModal === "Heat Stress" ? (
				<HeatStressChartModal
					setIsSelected={setChartModal}
					isSelected={chartModal}
					chartData={projectedChartData}
					startDate={2024}
					endDate={2099}
					isHistoricChart={false}
					title={`${projectedChartData?.indexName}_${
						projectedChartData?.asset
					}_${projectedChartData?.location}_${
						projectedTableScenario === "SSP5-8.5"
							? "ssp585"
							: "ssp245"
					}`}
					modalTitle={`${projectedChartData?.indexName} chart`}
					is_585={projectedTableScenario}
				/>
			) : chartModal === "Extreme Precipitation" ? (
				<ExtremePreciChartModal
					setIsSelected={setChartModal}
					isSelected={chartModal}
					chartData={projectedChartData}
					startDate={2024}
					endDate={2099}
					isHistoricChart={false}
					title={`${projectedChartData?.indexName}_${
						projectedChartData?.asset
					}_${projectedChartData?.location}_${
						projectedTableScenario === "SSP5-8.5"
							? "ssp585"
							: "ssp245"
					}`}
					modalTitle={`${projectedChartData?.indexName} chart`}
					is_585={projectedTableScenario}
				/>
			) : (
				<></>
			)}
			<section className="text-gray-600 px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="flex justify-center mt-4">
							<div className="h-[400px] w-full bg-white shadow-lg rounded-md border border-gray-200 my-4 mb-8 text-center relative">
								<Map data={locations} />
							</div>
						</div>
						<div className="text-lg font-bold">
							Portfolio Heat Map
						</div>
						<div className="mb-2">
							Exposure weighted average of individual asset score
							in the portfolio
						</div>
						<div className="overflow-x-auto">
							<ProjectedClimateRiskTable
								location={portfolio}
								getRiskColor={getRiskColor}
							/>
							<div className="mt-2">
								<sup className="font-bold text-sm">*</sup>
								Climate change scenarios considered - SSP3-7.0
								and SSP5-8.5
							</div>
							<div className="text-lg font-bold mt-8 mb-0">
								Asset-wise Hazard Score
							</div>
							{tableData?.map((location) => (
								<div className="py-4">
									<div className="text-base mb-2 font-medium uppercase">
										Asset:{" "}
										<span className="font-normal normal-case pl-2">
											{location?.asset}
										</span>
									</div>
									<ProjectedClimateRiskTable
										location={location}
										getRiskColor={getRiskColor}
										setChartModal={setChartModal}
										setProjectedChartData={
											setProjectedChartData
										}
									/>
								</div>
							))}
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PhysicalClimateRiskScoreProjected;
