import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/Loading/Loader";
import SuccessModal from "../../components/Modal/SuccessModal";
import ErrorModal from "../../components/Modal/ErrorModal";
import PhysicalClimateRiskScoreProjected from "./components/PhysicalClimateRiskScoreProjected";
import { FUTURE_TAB_NAMES } from "../../constants/tabNames";
import { projectedRiskOptions } from "../../constants/projectedClimateRisk";
import ClimateFinancialRiskScoreProjected from "./components/ClimateFinancialRiskScoreProjected";
import ClimateFinancialImpactProjected from "./components/ClimateFinancialImpactProjected";

const ProjectedAssessment = () => {
	const [loading, setLoading] = useState(false);
	const [projectedTableScenario, setProjectedTableScenario] = useState(
		projectedRiskOptions[1].value
	);
	const [activeTab, setActiveTab] = useState("Climate Hazard Score");
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	return (
		<div className={`mb-8 -mx-4 ${loading ? "relative h-[80vh]" : ""}`}>
			{loading && <Loader />}
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center mb-2 pl-2 md:pl-1 lg:pl-2 xl:pl-2 sticky top-0 z-20 bg-[#f7f8f9] -mt-[2px]">
					<div className="lg:mb-8 xl:mb-3 xl:pt-1">
						<div className="flex items-center gap-4 ">
							<h2 className="text-left text-2xl pl-2 font-semibold">
								Projections
							</h2>
							<div className="text-base font-medium ml-3">
								Scenario
							</div>
							<div className="text-base font-normal">
								<div className="flex gap-2">
									{projectedRiskOptions.map((option, i) => (
										<div className="flex items-center">
											<label
												name={`radio-${i}`}
												className="label cursor-pointer"
											>
												<input
													type="radio"
													name={`radio-${i}`}
													className="radio-xs cursor-pointer"
													value={option.value}
													checked={
														projectedTableScenario ===
														option.value
													}
													onChange={() => {
														setProjectedTableScenario(
															option.value
														);
													}}
												/>
												<span className="label-text ml-1">
													{option.label}
												</span>
											</label>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="-mt-2 ml-64">
							{projectedTableScenario ===
							projectedRiskOptions[0].value
								? `“Middle of the Road” socioeconomic pathway where
							radiative forcing reaches 4.5 W/m² by 2100`
								: `“Fossil-fuelled Development” with a high emissions pathway where radiative forcing reaches 8.5 W/m² by 2100`}
						</div>
					</div>
				</div>
				<div className="w-full mx-auto mt-2 rounded-md">
					<nav className="mx-4 box-shadow5 flex items-center  text-[#fff]  viewPool-navbar sticky top-14 z-20 bg-[#f7f8f9]">
						<ul className="flex  w-full ">
							<li
								className={`w-1/3 cursor-default ${
									activeTab ===
									FUTURE_TAB_NAMES.ClimateHazardScore
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick(
										FUTURE_TAB_NAMES.ClimateHazardScore
									)
								}
							>
								<span>Climate Hazard Score</span>
							</li>
							<li
								className={`w-1/3 cursor-default ${
									activeTab === FUTURE_TAB_NAMES.CFRScore
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick(FUTURE_TAB_NAMES.CFRScore)
								}
							>
								<span>Climate Financial Risk Score</span>
							</li>
							<li
								className={`w-1/3 cursor-default ${
									activeTab === FUTURE_TAB_NAMES.CFI
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick(FUTURE_TAB_NAMES.CFI)
								}
							>
								<span>Climate Financial Impact</span>
							</li>
						</ul>
					</nav>
					<div className="text-slate-800">
						{activeTab === FUTURE_TAB_NAMES.ClimateHazardScore && (
							<PhysicalClimateRiskScoreProjected
								setLoading={setLoading}
								projectedTableScenario={projectedTableScenario}
							/>
						)}
						{activeTab === FUTURE_TAB_NAMES.CFRScore && (
							<ClimateFinancialRiskScoreProjected
								setLoading={setLoading}
								projectedTableScenario={projectedTableScenario}
							/>
						)}
						{activeTab === FUTURE_TAB_NAMES.CFI && (
							<ClimateFinancialImpactProjected
								setLoading={setLoading}
								projectedTableScenario={projectedTableScenario}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectedAssessment;
