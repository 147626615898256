import React, { useContext, useEffect, useState } from "react";
import Map from "../../../components/Map/Map";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import VulnerabilityScoreTable from "./Tables/VulnerabilityScoreTable";
import { getLocationsFromAssets } from "../../../services/Helpers/locationHelper";
import { getVulnerabilityScore } from "../../../services/ApiCalls/climateRiskCall";

const ClimateVulnerabilityScoreCurrent = ({ setLoading }) => {
	const [vulnerabilityScoreTable, setVulnerabilityScoreTable] = useState([]);
	const [locations, setLocations] = useState();
	const [isExpanded, setIsExpanded] = useState(false);
	const { userData } = useContext(LoginContext);
	useEffect(() => {
		(async () => {
			setLoading(true);
			if (userData?.id) {
				const vulnerabilityScore = await getVulnerabilityScore(
					userData?.id
				);
				const riskArray = vulnerabilityScore?.data.map(
					(score) => score?.["Vulnerability Category Index"]
				);
				if (userData?.assets) {
					const locationsData = getLocationsFromAssets(
						userData?.assets,
						riskArray
					);
					setLocations(locationsData);
				}
				setVulnerabilityScoreTable(vulnerabilityScore?.data);
			}

			setLoading(false);
		})();
	}, [userData]);

	const toggleView = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div>
			<section className="text-gray-600  px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="text-lg font-semibold mb-4">
							Definition and context
						</div>
						<div className="">
							<div>
								<span>
									Location wise vulnerability presented below
									assesses climate vulnerability across Indian
									districts, focusing on the&nbsp;
								</span>
								<span className="font-semibold">
									combined effects of climate change,
									socio-economic factors, and environmental
									stressors.&nbsp;
								</span>
								<span>
									The composite vulnerability index is created
									by&nbsp;
								</span>
								<span className="font-semibold">
									integrating various socio-economic
									indicators across multiple sensitivity, and
									adaptive capacity&nbsp;
								</span>
								<span>like:</span>
							</div>
							{isExpanded && (
								<>
									<div className="mt-4">
										<span className="underline underline-offset-2">
											1. Socio-economic and
											livelihood-based indicators:
										</span>
										<span>
											&nbsp;Percentage of BPL population
											in rural areas, women's
											participation in the workforce and
											the female literacy rate etc.
										</span>
									</div>
									<div>
										<span className="underline underline-offset-2">
											2. Biophysical indicators:
										</span>
										<span>
											&nbsp;The yield variability of food
											grains, the proportion of area under
											rainfed agriculture and the forest
											area per 100 rural population etc.
										</span>
									</div>

									<div className="mb-4">
										<span className="underline underline-offset-2">
											3. Institution and infrastructure
											related indicators:
										</span>
										<span>
											&nbsp;Road density, the health
											infrastructure per 1000 population,
											and the percentage of households
											with an improved drinking water
											source etc.
										</span>
									</div>

									<div>
										- Methodology based on "District Level
										Vulnerability Indices" report by
										Department of Science & Technology
										(DST), Government of India, December
										2023
									</div>
								</>
							)}

							<span
								onClick={toggleView}
								className="text-blue-500 underline cursor-pointer"
							>
								{isExpanded ? " less..." : " more..."}
							</span>
						</div>
						<div className="flex justify-center mt-4">
							<div className="h-[400px] w-full bg-white shadow-lg rounded-md border border-gray-200 my-4 mb-8 text-center relative">
								<Map data={locations} />
							</div>
						</div>
						<div className="overflow-x-auto">
							<VulnerabilityScoreTable
								tableData={vulnerabilityScoreTable}
							/>
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ClimateVulnerabilityScoreCurrent;
