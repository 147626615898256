export function sortByPropertyAscending(property) {
	return function (a, b) {
		if (a[property] > b[property]) return 1;
		else if (a[property] < b[property]) return -1;

		return 0;
	};
}

export function sortArrayByProperty(arr, property) {
	return arr.sort((a, b) => {
		const valueA = a[property]?.toString().toLowerCase() || ""; // Handle undefined/null
		const valueB = b[property]?.toString().toLowerCase() || "";

		return valueA.localeCompare(valueB, undefined, {
			numeric: true,
			sensitivity: "base",
		});
	});
}
