export const timePeriodOptions = [
	{
		label: "2030",
		value: 2030,
	},
	{
		label: "2050",
		value: 2050,
	},
	{
		label: "2080",
		value: 2080,
	},
];

export const climateHazardOptions = [
	{
		label: "Fluvial Flood",
		value: "Fluvial Flood",
	},
	{
		label: "Pluvial Flood",
		value: "Pluvial Flood",
	},
	{
		label: "Heat Stress",
		value: "Heat Stress",
	},
	{
		label: "All Perils",
		value: "All Perils",
	},
];
