import { captureException } from "@sentry/react";
import axiosHttpService from "../axioscall";

export const getNewsData = async (query) => {
	if (!query || query === "") {
		return;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/news`,
			method: "GET",
			headers: {},
			params: {
				query: query,
			},
		};
		const result = await axiosHttpService(options);
		if (result.res.status === "success") {
			return result.res;
		}
	} catch (error) {
		captureException(error);
	}
};
