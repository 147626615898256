export const hazardOptions = [
	{
		label: "Drought",
		value: "'Drought' or 'less rainfall' or 'no rainfall'",
	},
	{
		label: "Heat Stress",
		value: "'Heat Stress' or 'heat stroke' or 'heat wave' or 'high temperature'",
	},
	{
		label: "Extreme Precipitation",
		value: "'Extreme Precipitation' or 'heavy rainfall' or 'waterlogging' or 'flood'",
	},
	{
		label: "Water Stress",
		value: "'Water Stress' or 'Depleting groundwater' or 'depleting water table' or 'lack of water' or 'water shortage'",
	},
	{
		label: "Riverine Flood",
		value: "'Riverine Flood' or 'flood' or 'flash flood'",
	},
	{
		label: "Flash Flood",
		value: "'Flash Flood' or 'flood' or 'flash flood'",
	},
	{
		label: "Coastal Flood",
		value: "'Coastal Flood' or 'flood' or 'flash flood'",
	},
];
