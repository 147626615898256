export const computeTrendline = (x, y) => {
	// Use the shorter array length
	const minLength = Math.min(x.length, y.length);

	// Slice arrays to the same length
	const validX = x.slice(0, minLength);
	const validY = y.slice(0, minLength);

	// Calculate sums
	const n = validX.length;
	const sumX = validX.reduce((acc, val) => acc + val, 0);
	const sumY = validY.reduce((acc, val) => acc + val, 0);
	const sumXY = validX.reduce((acc, val, idx) => acc + val * validY[idx], 0);
	const sumXX = validX.reduce((acc, val) => acc + val * val, 0);

	// Compute slope and intercept
	const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
	const intercept = (sumY - slope * sumX) / n;

	// Calculate trendline
	const trendline = validX.map((xi) => slope * xi + intercept);
	return trendline;
};
