import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const YearRangePicker = ({ onChange, startDate, endDate, className }) => {
	const disabledDate = (current) => {
		const year = current.year();
		return year < startDate || year > endDate;
	};
	return (
		<RangePicker
			picker="year"
			onChange={onChange}
			disabledDate={disabledDate}
			className={`rounded-md border border-gray-200 shadow-sm outline-none ${
				className ? className : ""
			}`}
		/>
	);
};

export default YearRangePicker;
