import React from "react";
import { useTable, useSortBy } from "react-table";
import DownArrowSvg from "../../../../assets/DynamicIcons/DownArrowSvg";
import UpArrowSvg from "../../../../assets/DynamicIcons/UpArrowSvg";
import DroughtImg from "../../../../assets/IndexIcons/Drought.png";
import HeatStressImg from "../../../../assets/IndexIcons/Heat Stress.png";
import ExtremePrecipitationImg from "../../../../assets/IndexIcons/Extreme Precipitation.png";
import WaterStressImg from "../../../../assets/IndexIcons/Water stress.png";
import RiverineFloodingImg from "../../../../assets/IndexIcons/RiverineFlooding.png";
import FlashFloodingImg from "../../../../assets/IndexIcons/PluvialFlooding.png";
import { roundNumber } from "../../../../services/Helpers/displayTextHelper";
import LineChartSvg from "../../../../assets/DynamicIcons/LineChartSvg";

const CurrentClimateRiskTable = ({
	tableData,
	getRiskColor,
	setChartModal,
	setCurrentChartData,
	isFinancial = false,
}) => {
	const data = React.useMemo(() => tableData, [tableData]);

	const columns = React.useMemo(() => {
		const baseColumns = [
			{
				Header: <span className="px-3">Asset Name</span>,
				accessor: "asset",
			},
			{
				Header: (
					<div className="">
						<span className="ml-1">Overall Score</span>
					</div>
				),
				accessor: "maxPeril",
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{roundNumber(value)}</>
					) : (
						"coming soon"
					);
				},
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img src={DroughtImg} width={25} alt="draughtIcon" />
						<span className="ml-1">Drought</span>
					</div>
				),
				accessor: "Drought",
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{roundNumber(value)}</>
					) : (
						"coming soon"
					);
				},
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={HeatStressImg}
							width={25}
							alt="heatStressIcon"
						/>
						<span className="ml-1">Heat Stress</span>
					</div>
				),
				accessor: "Heat Stress",
				Cell: ({ value, row }) => {
					return value !== undefined ? (
						<div className="relative">
							{roundNumber(value)}
							{setChartModal && (
								<div
									className="cursor-pointer absolute -right-6 -bottom-3"
									onClick={() => {
										setChartModal("Heat Stress");
										setCurrentChartData({
											indexName: "Heat Stress",
											latlon: row.original.latlon,
											asset: row.original.asset,
											location: row.original.location,
										});
									}}
								>
									<LineChartSvg />
								</div>
							)}
						</div>
					) : (
						"coming soon"
					);
				},
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={WaterStressImg}
							width={23}
							alt="waterStressIcon"
						/>
						<span className="ml-1">Water Stress</span>
					</div>
				),
				accessor: "Water Stress",
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{roundNumber(value)}</>
					) : (
						"coming soon"
					);
				},
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={RiverineFloodingImg}
							width={23}
							alt="riverineFloodIcon"
						/>
						<span className="ml-1">Fluvial Flood</span>
					</div>
				),
				accessor: "Fluvial Flood",
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{roundNumber(value)}</>
					) : (
						"coming soon"
					);
				},
			},
			{
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={FlashFloodingImg}
							width={25}
							alt="flashFloodIcon"
						/>
						<span className="ml-1">Pluvial Flood</span>
					</div>
				),
				accessor: "Pluvial Flood",
				Cell: ({ value }) => {
					return value !== undefined ? (
						<>{roundNumber(value)}</>
					) : (
						"coming soon"
					);
				},
			},
		];
		if (!isFinancial) {
			baseColumns.splice(3, 0, {
				Header: (
					<div className="flex items-center justify-center">
						<img
							src={ExtremePrecipitationImg}
							width={23}
							alt="extremePrecipitationIcon"
						/>
						<div className="ml-1">
							<div>Extreme</div>
							<div>Precipitation</div>
						</div>
					</div>
				),
				accessor: "Extreme Precipitation",
				Cell: ({ value, row }) => {
					return value !== undefined ? (
						<div className="relative">
							{roundNumber(value)}
							{setChartModal && (
								<div
									className="cursor-pointer absolute -right-6 -bottom-3"
									onClick={() => {
										setChartModal("Extreme Precipitation");
										setCurrentChartData({
											indexName: "Extreme Precipitation",
											latlon: row.original.latlon,
											asset: row.original.asset,
											location: row.original.location,
										});
									}}
								>
									<LineChartSvg />
								</div>
							)}
						</div>
					) : (
						"coming soon"
					);
				},
			});
		}
		return baseColumns;
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data }, useSortBy);

	return (
		<>
			{tableData.length ? (
				<div className="relative overflow-x-auto overflow-y-auto max-h-96 rounded-md border border-gray-300 w-full show-scrollbar">
					<table
						{...getTableProps()}
						className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
					>
						<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
							{headerGroups.map((headerGroup) => (
								<tr
									{...headerGroup.getHeaderGroupProps()}
									className="border-b-[1px] border-gray-300"
								>
									{headerGroup.headers.map((column) => (
										<th
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
											className="px-4 py-3 border-l border-gray-300 select-none relative w-[12.5%]"
											align="center"
										>
											<div className="flex justify-center items-center">
												{column.render("Header")}
												{column.isSorted && (
													<span
														className={`ml-2 absolute right-1 ${
															isFinancial
																? "top-3"
																: "top-3 pt-[4px]"
														}`}
													>
														{column.isSortedDesc ? (
															<DownArrowSvg />
														) : (
															<UpArrowSvg />
														)}
													</span>
												)}
											</div>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										className="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
										align="center"
									>
										{row.cells.map((cell) => (
											<td
												{...cell.getCellProps()}
												className={`px-6 py-4 border-l border-gray-300 ${getRiskColor(
													cell.value
												)} text-black`}
											>
												{cell.render("Cell")}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : (
				<div className="text-neutral-500 text-lg">No data found</div>
			)}
		</>
	);
};

export default CurrentClimateRiskTable;
