import React, { useEffect, useState, useContext } from "react";
import TextArea from "../../../../components/Inputs/TextArea";
import Selection from "../../../../components/Inputs/Selection";
import YearRangePicker from "../../../../components/RangePicker/YearRangePicker";
import { hazardOptions } from "../../../../constants/selectionOptions";
import { LoginContext } from "../../../../LoginProvider/LoginProvider";
import { getNewsData } from "../../../../services/ApiCalls/newsCalls";
import CopyIcon from "../../../../assets/DynamicIcons/CopyIcon";
import TickMark from "../../../../assets/DynamicIcons/TickMark";
import SearchIcon from "../../../../assets/search.png";

const NewsSearchModal = ({ isSelected, setIsSelected }) => {
	const [assetOptions, setAssetOptions] = useState([]);
	const [asset, setAsset] = useState();
	const [hazard, setHazard] = useState(hazardOptions[0].value);
	const [yearRange, setYearRange] = useState(["", ""]);
	const [assetOutline, setAssetOutline] = useState(false);
	const [hazardOutline, setHazardOutline] = useState(false);
	const [query, setQuery] = useState("");
	const [results, setResults] = useState();
	const [copyButtonText, setCopyButtonText] = useState({
		status: false,
		text: "Copy all links",
	});
	const [copiedLinks, setCopiedLinks] = useState("");
	const [isSubmit, setIsSubmit] = useState(false);
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		(async () => {
			if (userData?.assets) {
				const assetData = userData?.assets.map((asset) => {
					return {
						label: asset?.["Asset Name"],
						value: asset?.["Location"],
					};
				});
				setAssetOptions(assetData);
				setAsset(assetData?.[0]?.value);
			}
		})();
	}, [userData]);

	useEffect(() => {
		setQuery(`${hazard} in ${asset}`);
	}, [asset, hazard]);

	const onChange = (dates, dateStrings) => {
		setYearRange(dateStrings);
	};

	const handleSubmit = async () => {
		setIsSubmit(true);
		const data = await getNewsData(query);
		let newsResults = data.data;
		if (yearRange[0] !== "" && yearRange[1] !== "") {
			const filteredNews = newsResults.filter((news) => {
				const dateArray = news.upload_time.split(" ");
				const year =
					dateArray.length === 3
						? dateArray[2]
						: new Date().getFullYear();
				if (+yearRange[0] <= +year && +year <= +yearRange[1]) {
					return true;
				}
			});
			newsResults = filteredNews;
		}
		setResults(newsResults);
		const clipboard = newsResults.reduce((acc, news, index) => {
			const title = news.title;
			const dateArray = news.upload_time.split(" ");
			const date = `${dateArray[0]} ${dateArray[1]}, ${
				dateArray.length === 3 ? dateArray[2] : new Date().getFullYear()
			}`;
			const url = news.article_link;
			return `${acc}${
				index + 1
			}.\nTitle- ${title}\nDate- ${date}\nUrl- ${url}\n\n`;
		}, "");
		setCopiedLinks(clipboard);
		setIsSubmit(false);
	};

	const handleCopyLinks = () => {
		navigator.clipboard.writeText(copiedLinks);
		setCopyButtonText({
			status: true,
			text: "All links copied!",
		});
		setTimeout(() => {
			setCopyButtonText({
				status: false,
				text: "Copy all links",
			});
		}, 3000);
	};

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal text-black"
			>
				<div className="relative p-4 w-full max-w-4xl h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<div className="flex gap-2 items-center">
								<img src={SearchIcon} width="22px" />
								<h3 className="font-normal text-xl">
									Climate news search
								</h3>
							</div>
							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>
						<div>
							<div className="px-6 py-4">
								<div className="text-lg mb-2">
									Search for news:
								</div>
								<div className="flex w-full justify-between">
									<div>
										<div className="mb-1">Assets</div>
										<div>
											<div
												className={`h-10 input input-bordered w-56 items-center flex justify-center dark:bg-[#24272F] bg-[#E7EAEE] ${
													assetOutline
														? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#caccce]"
														: ""
												}`}
											>
												<Selection
													onChange={(value) => {
														setAsset(value.value);
													}}
													value={asset}
													placeholder="Select&nbsp;Options"
													options={assetOptions}
													focus={setAssetOutline}
													className=" w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
												></Selection>
											</div>
										</div>
									</div>
									<div>
										<div className="mb-1">Hazard</div>
										<div>
											<div
												className={`h-10 input input-bordered w-56 items-center flex justify-center dark:bg-[#24272F] bg-[#E7EAEE] ${
													hazardOutline
														? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#caccce]"
														: ""
												}`}
											>
												<Selection
													onChange={(value) => {
														setHazard(value.value);
													}}
													value={hazard}
													placeholder="Select&nbsp;Options"
													options={hazardOptions}
													focus={setHazardOutline}
													className=" w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
												></Selection>
											</div>
										</div>
									</div>
									<div className="">
										<div className="mb-1">Timeline</div>
										<div>
											<YearRangePicker
												onChange={onChange}
												startDate={1981}
												endDate={2024}
												className="w-full rounded-md border border-[#c2c6d2] px-2 py-2 shadow-sm outline-none hover:border-[#c2c6d2] focus-within:border-[#c2c6d2] bg-lightmode-200 hover:bg-lightmode-200 focus-within:bg-lightmode-200"
											/>
										</div>
									</div>
								</div>
								<div className="pt-6 pb-2">
									<div className="mb-1">Keywords</div>
									<TextArea
										value={query}
										onChange={(e) => {
											setQuery(e.target.value);
										}}
										rows={2}
										name="keywords"
										placeholder="Enter Keywords"
										className="w-full"
									/>
								</div>
								<button
									type="button"
									className={`relative py-2 px-6 bg-gradient-to-r ${
										!isSubmit
											? "from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF]"
											: "bg-neutral-400 cursor-not-allowed opacity-60"
									} capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]`}
									onClick={handleSubmit}
									disabled={query === "" || isSubmit}
								>
									Submit
									{isSubmit && (
										<div className="animate-spin border-solid  top-3 left-10 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
									)}
								</button>
							</div>
							{results && (
								<div className="md:border-t md:border-neutral-300">
									<div className="flex justify-between items-center py-1">
										<div className="text-lg px-6 py-2">
											Results:
										</div>
										<button
											class="text-gray-900 hover:bg-gray-100 rounded-lg py-2 px-2 inline-flex items-center justify-center bg-white border-gray-200 border mr-9 h-9"
											onClick={handleCopyLinks}
										>
											<span class="inline-flex items-center">
												{copyButtonText.status ? (
													<CopyIcon />
												) : (
													<TickMark />
												)}
												<span
													class={`text-sm font-medium ${
														copyButtonText.status &&
														"text-blue-700"
													}`}
												>
													{copyButtonText.text}
												</span>
											</span>
										</button>
									</div>
									<div className="px-6 pb-4 pt-0 max-h-72 show-scrollbar ">
										<div className="rounded-xl bg-gray-100">
											{results.length ? (
												results.map((result) => {
													return (
														<div
															className="border-b border-gray-300 last:border-none"
															key={
																result?.article_link
															}
														>
															<div className="flex justify-between px-5 py-4 ">
																<div
																	className="text-lg max-w-[30rem] flex flex-col justify-between"
																	onClick={() => {
																		window.open(
																			result?.article_link,
																			"_blank"
																		);
																	}}
																>
																	<div className="flex flex-col gap-[.35rem]">
																		<div className="flex items-center gap-1">
																			<img
																				className="h-[12px]"
																				src={
																					result?.source_img
																				}
																			/>
																			{result?.src_text && (
																				<div className="text-xs">
																					{
																						result?.src_text
																					}
																				</div>
																			)}
																		</div>
																		<div className="hover:cursor-pointer hover:underline">
																			{
																				result?.title
																			}
																		</div>
																	</div>
																	<div className="text-sm">
																		{
																			result?.upload_time
																		}
																	</div>
																</div>
																<img
																	className="rounded-xl"
																	src={`${
																		process
																			.env
																			.REACT_APP_FIREBASE_FUNCTIONS_URI
																	}/news/proxy-image?url=${encodeURIComponent(
																		result?.image
																	)}`}
																/>
															</div>
														</div>
													);
												})
											) : (
												<div className="p-4">
													No news found
												</div>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewsSearchModal;
