import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
	getUserAccountStatus,
	enableDisableUser,
	downloadExcel,
} from "../../../services/ApiCalls/userCall";
import {
	processRainHistoric,
	processTmaxHistoric,
	processTminHistoric,
	processPetHistoric,
	processSpeiHistoric,
	processExtremeHeatDaysHistoric,
	processHeatIndexHistoric,
	processHeatStressHistoric,
	processExtremePrecipitationHistoric,
	processWaterStressHistoric,
	processExecuteAllPerils,
} from "../../../services/ApiCalls/cdpHistoricCalls";
import Selection from "../../../components/Inputs/Selection";
import {
	processExtremePrecipitationFuture,
	processHeatStressFuture,
	processPetFuture,
	processSpeiFuture,
	processWaterStressFuture,
} from "../../../services/ApiCalls/cdpFutureCalls";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import { actionOptions } from "../../../constants/adminActions";

const UsersCard = ({
	data,
	setSelectedUploadModal,
	setCurrentUser,
	setErrormsg,
	setSuccessmsg,
	setLoading,
	setSelectedDeleteUserModal,
	setItem,
	isUserManagementPage = true,
}) => {
	const [locationsOptions, setLocationsOptions] = useState();
	const [location, setLocation] = useState();
	const [coordinate, setCoordinate] = useState();
	const [action, setAction] = useState();
	const [isDisabled, setIsDisabled] = useState(false);
	const [locationOutline, setLocationOutline] = useState(false);
	const [actionOutline, setActionOutline] = useState(false);
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		getUserAccountStatus(data.email).then((result) => {
			setIsDisabled(result?.data.disabled);
		});
	}, [isDisabled]);

	useEffect(() => {
		if (data?.assets) {
			const locations = data?.assets.map((asset) => {
				return {
					label: asset?.Location,
					value: `${asset.Lat},${asset.Long}`,
				};
			});

			setLocationsOptions(locations);
			setLocation(locations[0].label);
			setCoordinate(locations[0].value);
		}
		setAction(actionOptions[0].value);
	}, [data]);

	const handleExecute = () => {
		try {
			setLoading(true);
			switch (action) {
				case 0:
					processRainHistoric(userData?.email, location, coordinate);
					break;
				case 1:
					processTminHistoric(userData?.email, location, coordinate);
					break;
				case 2:
					processTmaxHistoric(userData?.email, location, coordinate);
					break;
				case 3:
					processPetHistoric(userData?.email, location, coordinate);
					break;
				case 4:
					processSpeiHistoric(userData?.email, location, coordinate);
					break;
				case 5:
					processExtremeHeatDaysHistoric(
						userData?.email,
						location,
						coordinate
					);
					break;
				case 6:
					processHeatIndexHistoric(
						userData?.email,
						location,
						coordinate
					);
					break;
				case 7:
					processHeatStressHistoric(
						userData?.email,
						location,
						coordinate
					);
					break;
				case 8:
					processExtremePrecipitationHistoric(
						userData?.email,
						location,
						coordinate
					);
					break;
				case 9:
					processWaterStressHistoric(
						userData?.email,
						location,
						coordinate
					);
					break;
				case 10:
					processPetFuture(
						userData?.email,
						location,
						coordinate,
						245
					);
					break;
				case 11:
					processPetFuture(
						userData?.email,
						location,
						coordinate,
						585
					);
					break;
				case 12:
					processSpeiFuture(
						userData?.email,
						location,
						coordinate,
						245
					);
					break;
				case 13:
					processSpeiFuture(
						userData?.email,
						location,
						coordinate,
						585
					);
					break;
				case 14:
					processHeatStressFuture(
						userData?.email,
						location,
						coordinate,
						245
					);
					break;
				case 15:
					processHeatStressFuture(
						userData?.email,
						location,
						coordinate,
						585
					);
					break;
				case 16:
					processExtremePrecipitationFuture(
						userData?.email,
						location,
						coordinate,
						245
					);
					break;
				case 17:
					processExtremePrecipitationFuture(
						userData?.email,
						location,
						coordinate,
						585
					);
					break;
				case 18:
					processWaterStressFuture(
						userData?.email,
						location,
						coordinate
					);
					break;
				default:
					setErrormsg({
						status: true,
						msg: "Failed to trigger",
					});
					return;
			}

			setSuccessmsg({
				status: true,
				msg: "Process triggered successfully",
			});
		} catch (error) {
			Sentry.captureException(error);
			setErrormsg({
				status: true,
				msg: "Failed to trigger",
			});
		} finally {
			setLoading(false);
		}
	};

	const handleExecuteAll = () => {
		try {
			processExecuteAllPerils(
				userData?.email,
				location,
				coordinate,
				userData?.idToken
			);
			setSuccessmsg({
				status: true,
				msg: "Process triggered successfully",
			});
		} catch (error) {
			Sentry.captureException(error);
			setErrormsg({
				status: true,
				msg: "Failed to trigger",
			});
		} finally {
			setLoading(false);
		}
	};

	const handleEnableDisable = async () => {
		try {
			setLoading(true);
			const result = await enableDisableUser(data.email);
			if (result?.status === "success") {
				setIsDisabled(result.data.disabled);
				setSuccessmsg({
					status: true,
					msg: `User Account ${
						result?.data?.disabled ? "Disabled" : "Enabled"
					}`,
				});
			} else {
				setErrormsg({
					status: true,
					msg: "User does not exists...",
				});
			}
			setLoading(false);
		} catch (error) {
			Sentry.captureException(error);
			setErrormsg({
				status: true,
				msg: "User does not exists...",
			});
			setLoading(false);
		}
	};

	const handledownloadExcel = async () => {
		try {
			setLoading(true);
			const result = await downloadExcel(data.id);
			if (result.status === "success") {
				window.open(result.data, "_blank");
			} else {
				setErrormsg({
					status: true,
					msg: "Failed to download excel",
				});
			}
			setLoading(false);
		} catch (error) {
			Sentry.captureException(error);
			setErrormsg({
				status: true,
				msg: "User does not exists...",
			});
			setLoading(false);
		}
	};

	return (
		<div className="flex collapse-title pr-0 justify-around w-full flex-wrap dark:bg-[#20232A] bg-[#ecedef] rounded-xl mb-2 items-center">
			<p className="w-1/3 md:w-2/12 font-light text-lg text-center">
				{data?.email}
			</p>
			{isUserManagementPage ? (
				<>
					<div className="w-1/3 md:w-5/12 text-center">
						<button
							className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#1059b9] btn btn-xs btn-outline text-[#1059b9] text-base  capitalize font-medium"
							onClick={handleEnableDisable}
						>
							{isDisabled ? "Enable" : "Disable"}
						</button>
					</div>
					<div className="w-1/3 md:w-5/12 text-center">
						<button
							className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#b91010] btn btn-xs btn-outline text-[#b91010] text-base  capitalize font-medium"
							onClick={() => {
								setItem(data);
								setSelectedDeleteUserModal(true);
							}}
						>
							Delete
						</button>
					</div>
				</>
			) : (
				<>
					<p className="w-1/3 md:w-2/12 text-center">
						<button
							className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#9a10b9] btn btn-xs btn-outline text-[#9a10b9] text-base  capitalize font-medium mr-2"
							onClick={() => {
								setCurrentUser(data);
								setSelectedUploadModal(true);
							}}
						>
							Upload
						</button>
					</p>
					<p className="w-1/3 md:w-2/12 text-center">
						<button
							className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#16a4d7] btn btn-xs btn-outline text-[#16a4d7] text-base  capitalize font-medium mr-2"
							onClick={handledownloadExcel}
						>
							Down...
						</button>
					</p>
					<div className="w-1/3 md:w-6/12 text-center flex  justify-center items-center gap-2">
						<div
							className={`input input-bordered w-36 items-center flex justify-center dark:bg-[#24272F] bg-[#E7EAEE] ${
								locationOutline
									? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#caccce]"
									: ""
							}`}
						>
							<Selection
								onChange={(value) => {
									setCoordinate(value.value);
									setLocation(value.label);
								}}
								value={coordinate}
								placeholder="Enter&nbsp;Location"
								options={locationsOptions}
								focus={setLocationOutline}
								className=" w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
							></Selection>
						</div>
						<div
							className={`input input-bordered w-36 items-center flex justify-center dark:bg-[#24272F] bg-[#E7EAEE] ${
								actionOutline
									? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#caccce]"
									: ""
							}`}
						>
							<Selection
								onChange={(value) => {
									setAction(value.value);
								}}
								value={action}
								placeholder="Enter&nbsp;Action"
								options={actionOptions}
								focus={setActionOutline}
								className=" w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
							></Selection>
						</div>
						<button
							className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#10B981] btn btn-xs btn-outline text-[#10B981] text-base  capitalize font-medium mr-2 self-center"
							disabled={!location}
							onClick={handleExecute}
						>
							Execute
						</button>
						<button
							className="rounded-full h-8 w-20  transition ease-linear duration-500 overflow-hidden border-2 border-[#1067b9] btn btn-xs btn-outline text-[#1067b9] text-base  capitalize font-medium mr-2 self-center"
							disabled={!location}
							onClick={handleExecuteAll}
						>
							Ex.. All
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default UsersCard;
