import { captureException } from "@sentry/react";
import { auth } from "../firebase";
import {
	signInWithEmailAndPassword,
	signOut,
	updatePassword,
} from "firebase/auth";
import { getUser } from "../ApiCalls/userCall";

export async function signInWithEmail(email, password) {
	if (!email && !password) {
		return undefined;
	}
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);

		if (userCredential) {
			const userData = await getUser(
				email,
				userCredential._tokenResponse.idToken
			);
			if (userData?.status === "success") {
				return {
					...userData.data,
					idToken: userCredential._tokenResponse.idToken,
				};
			}
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
}

export async function logoutUser() {
	try {
		await signOut(auth);
	} catch (error) {
		captureException(error);
	}
}

export async function changePassword(newPassword) {
	try {
		await updatePassword(auth.currentUser, newPassword);
		return true;
	} catch (error) {
		captureException(error);
		return false;
	}
}
