import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Selection from "../../../../components/Inputs/Selection";
import { sortByPropertyAscending } from "../../../../services/Helpers/sortItemsHelper";
import { kycStatusOptions } from "./ModalOptions/UserModalOptions";
import { captureException } from "@sentry/react";
import { editUser, uploadExcel } from "../../../../services/ApiCalls/userCall";
import FileUploader from "../../../../components/FileUploader/FileUploader";

const UploadExcelModal = ({
	user,
	selectedUploadModal,
	setSelectedUploadModal,
	setErrormsg,
	setSuccessmsg,
	setReloadData,
}) => {
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [loginResponse, setloginResponse] = useState(false);

	const formik = useFormik({
		initialValues: {
			locations: "",
		},
		validationSchema: Yup.object().shape({
			locations: Yup.mixed().label("Locations File").required(),
		}),
		onSubmit: async (values) => {
			setisSubmit(true);

			try {
				let result = await uploadExcel(values.locations[0], user.email);
				if (result?.status === "success") {
					setloginResponse({
						status: true,
						msg: "Locations uploaded Successfully!",
					});
					setSuccessmsg({
						status: true,
						msg: "Locations uploaded Successfully!",
					});
					setReloadData(Math.random());
				} else {
					setloginResponse({
						status: false,
						msg: result?.message
							? result?.message
							: "Failed to upload locations doc.",
					});
					setErrormsg({
						status: true,
						msg: result?.message
							? result?.message
							: "Failed to upload locations doc.",
					});
				}
			} catch (error) {
				captureException(error);
				setloginResponse({
					status: false,
					msg: "Failed to Edit User...",
				});
				setErrormsg({
					status: true,
					msg: "Failed to Edit User...",
				});
			} finally {
				setSelectedUploadModal(false);
				setisSubmit(false);
			}
		},
	});

	useEffect(() => {
		if (formik.values.locations !== "") {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}
		if (isSubmit) {
			setIsDisable(true);
		}
	}, [isSubmit, formik.values.locations]);
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={selectedUploadModal}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-md h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								Upload Location
							</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setSelectedUploadModal(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-6 w-full">
							<form onSubmit={formik.handleSubmit}>
								<div className="py-4">
									<div className="w-full md:mr-2 mb-5 md:mb-0">
										<div className="flex items-center  h-6 w-full">
											<FileUploader
												name="locations"
												handleFile={(file) => {
													formik.setFieldValue(
														"locations",
														file
													);
												}}
												onBlur={formik.handleBlur}
												error={
													formik.touched.locations &&
													formik.errors.locations
														? formik.errors
																.locations
														: null
												}
												label="Locations Doc"
												className="w-full md:mr-2 mb-5 md:mb-0"
												fileName={
													formik.values.locations
														? formik.values
																.locations[0]
																.name
														: ""
												}
											/>
										</div>
									</div>
								</div>
								{loginResponse && !loginResponse?.status && (
									<p
										style={{
											color: "red",
											margin: "0px",
										}}
									>
										<small>{loginResponse.msg}</small>
									</p>
								)}
								<div className="mt-10 flex  justify-around">
									<button
										onClick={() => {
											setSelectedUploadModal(false);
										}}
										data-modal-toggle="popup-modal"
										type="button"
										className={`py-2 px-12 border-2 border-neutral-500 capitalize font-medium hover:bg-gray-50 rounded-3xl`}
									>
										Cancel
									</button>
									<button
										disabled={
											formik.values.locations === ""
										}
										data-modal-toggle="popup-modal"
										type="submit"
										className={`${
											!isDisable
												? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
												: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
										}`}
									>
										Submit
										{isSubmit && (
											<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
										)}
									</button>
								</div>
								<div>
									{loginResponse && loginResponse?.status && (
										<p
											style={{
												color: "green",
												margin: "0px",
											}}
										>
											<small>{loginResponse.msg}</small>
										</p>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UploadExcelModal;
