import React, { useContext, useEffect, useState } from "react";
import { getClimateRiskChartData } from "../../../../services/ApiCalls/climateRiskCall";
import LineChart from "../../../../components/Chart/LineChart";
import YearRangePicker from "../../../../components/RangePicker/YearRangePicker";
import { computeTrendline } from "../../../../services/Helpers/chartHelper";
import { LoginContext } from "../../../../LoginProvider/LoginProvider";

const ExtremePreciChartModal = ({
	isSelected,
	setIsSelected,
	chartData,
	startDate,
	endDate,
	isHistoricChart = true,
	title,
	modalTitle,
	is_585,
}) => {
	const [config, setConfig] = useState([]);
	const [epStressConfig, setEpStressConfig] = useState([]);
	const [rainLayout, setRainLayout] = useState({});
	const [epDayslayout, setEpDaysLayout] = useState({});
	const [yearRange, setYearRange] = useState([]);
	const { userData } = useContext(LoginContext);
	useEffect(() => {
		(async () => {
			const { indexName, latlon } = chartData;
			const result = await getClimateRiskChartData(
				indexName,
				latlon,
				yearRange[0],
				yearRange[1],
				isHistoricChart,
				is_585
			);
			if (result?.status === "success") {
				const data = result.data.data;
				const years = result.data.years;
				const date_1d_max = data.date_1d_max_preci_map.map(
					(item) => item.max_preci
				);
				const date_5d_max = data.date_5d_max_preci_map.map(
					(item) => item.max_preci
				);
				const date_ep_stress = data.date_ep_stress_map.map(
					(item) => item.ep_exceed_days
				);
				const trendline1dMax = computeTrendline(years, date_1d_max);
				const trendline5dMax = computeTrendline(years, date_5d_max);
				const trendlineEpStress = computeTrendline(
					years,
					date_ep_stress
				);

				const configData = [
					{
						x: years,
						y: date_1d_max,
						type: "scatter",
						mode: "lines+markers",
						name: "Max 1 day rain",
						line: { shape: "spline" },
						marker: { size: 6, color: "#1f77b4" },
					},
					{
						x: years,
						y: trendline1dMax,
						type: "scatter",
						mode: "lines",
						name: "Max 1 day rain Trendline",
						line: { dash: "dash", color: "#1f77b4" },
					},
					{
						x: years,
						y: date_5d_max,
						type: "scatter",
						mode: "lines+markers",
						name: "Max 5 day rain",
						line: { shape: "spline", color: "#ff7f0e" },
						marker: { size: 6 },
					},
					{
						x: years,
						y: trendline5dMax,
						type: "scatter",
						mode: "lines",
						name: "Max 5 day rain Trendline",
						line: { dash: "dash", color: "#ff7f0e" },
					},
				];
				setConfig(configData);
				const epConfigData = [
					{
						x: years,
						y: date_ep_stress,
						type: "scatter",
						mode: "lines+markers",
						name: "Extreme Precipitation<br>Days (95th percentile)",
						line: { shape: "spline", color: "#1f77b4" },
						marker: { size: 6 },
					},
					{
						x: years,
						y: trendlineEpStress,
						type: "scatter",
						mode: "lines",
						name: "Extreme Precipitation<br>Days (95th percentile) Trendline",
						line: { dash: "dash", color: "#1f77b4" },
					},
				];
				setEpStressConfig(epConfigData);
				let layoutData = {
					xaxis: {
						title: "Years",
					},
					yaxis: {
						title: "Rainfall (mm)",
					},
					title: {
						text: title,
						font: {
							size: 18,
						},
					},
					showlegend: true,
					hovermode: "closest",
				};
				setRainLayout(layoutData);

				layoutData = {
					xaxis: {
						title: "Years",
					},
					yaxis: {
						title: "Days/year",
					},
					showlegend: true,
					hovermode: "closest",
					title: {
						text: title,
						font: {
							size: 18,
						},
					},
				};

				setEpDaysLayout(layoutData);
			}
		})();
	}, [yearRange]);

	const onChange = (dates, dateStrings) => {
		setYearRange(dateStrings);
	};

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal text-black"
			>
				<div className="relative p-4 w-full max-w-[8xl] h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">
								{modalTitle}
							</h3>
							<button
								type="button"
								className="z-30 absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>
						<div className="flex w-full flex-row-reverse p-2 mt-4">
							<YearRangePicker
								onChange={onChange}
								startDate={startDate}
								endDate={endDate}
							/>
						</div>
						<div className="overflow-hidden flex">
							<div className="p-6 w-full -mt-12">
								<LineChart
									data={config}
									layout={rainLayout}
									chartName={`${title}_${userData?.email}`}
								/>
							</div>
							<div className="p-6 w-full -mt-12">
								<LineChart
									data={epStressConfig}
									layout={epDayslayout}
									chartName={`${title}_${userData?.email}`}
								/>
							</div>
						</div>
						<div className="text-sm px-6 pb-4">
							<div className="flex gap-3">
								<p className="font-medium">
									Max 1 day rain (mm):
								</p>
								<p>
									The highest rainfall in a single day in a
									year.
								</p>
							</div>
							<div className="flex gap-3">
								<p className="font-medium">
									Max 5 day rain (mm):
								</p>
								<p>
									The highest total rainfall over any 5
									consecutive days in a year.
								</p>
							</div>
							<div className="flex gap-3">
								<p className="font-medium">
									Extreme Precipitation Days (95th
									percentile):
								</p>
								<p>
									The number of days when rainfall exceeds the
									95th percentile of historical daily
									rainfall.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ExtremePreciChartModal;
