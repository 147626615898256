export const collapseData = [
	{
		title: "Acute Hazards",
		content:
			"These are event-driven risks, such as extreme weather events like hurricanes, cyclones, floods, heat waves, or cold waves.",
	},
	{
		title: "Chronic Hazards",
		content:
			"These are longer-term shifts in climate patterns, such as rising temperatures, changes in precipitation patterns, sea level rise, ice melt, and permafrost thaw.",
	},
	{
		title: "Climate Change",
		content:
			"Climate change refers to long-term changes in the Earth’s climate, particularly an increase in average global temperatures. This happens mainly because human activities, like burning fossil fuels (coal, oil, and gas), release gases such as carbon dioxide (CO₂) into the atmosphere. These gases trap heat from the sun, causing the Earth to warm up. Due to this warming, the moisture holding capacity of the atmosphere increases manifold. As a result, we see more extreme weather events like stronger storms, extreme precipitation, droughts, rising sea levels, and melting glaciers, all of which affect the planet's ecosystems, wildlife, and human life.",
	},
	{
		title: "Climate Change Scenario",
		content:
			"Climate change scenarios are projections of how the Earth's climate might change in the future based on different levels of greenhouse gas emissions. These scenarios help scientists and policymakers understand potential climate impacts and guide decision-making. The most widely used set of climate change scenarios comes from the Intergovernmental Panel on Climate Change (IPCC) and is called Representative Concentration Pathways (RCPs) or Shared Socioeconomic Pathways (SSPs) in more recent models.",
	},
	{
		title: "Climate risk",
		content:
			"As per IPCC, the risk of climate-related impacts is a result of the interaction between climate-related hazards and the vulnerability and exposure of human and natural systems. Climate risk (CR) = hazard (H) x exposure (E) x vulnerability (V). This equation is the foundation for assessing climate risk.",
	},
	{
		title: "CMIP6 (Coupled Model Intercomparison Project Phase 6)",
		content:
			"Refers to the latest phase of a collaborative international effort to improve and standardize global climate models. These models simulate the Earth's climate system, including the atmosphere, ocean, cryosphere, and land surface, to project future climate changes. CMIP6 aims to provide more accurate and comprehensive climate projections by incorporating higher spatial resolution, new physical processes, and biogeochemical cycles.",
	},
	{
		title: "Coastal Flood",
		content:
			"A coastal flood occurs when normally dry, low-lying coastal land is inundated by seawater. Typically caused by a combination of factors such as high tides, storm surges, and sea-level rise, this metric indicates a normalized value basis the mean flood depth of a grid of X square kilometres around a given location for a 1-in-100 return period coastal flood assuming no flood protection measures.",
	},
	{
		title: "Coefficient of Variation",
		content:
			"Coefficient of Variation (CV) is a standardized measure of dispersion of a probability distribution or frequency distribution. It is expressed as a percentage and is useful for comparing the degree of variation from one dataset to another, even if they have different units or means.\nCV=SD/μx100\nSD is the standard deviation.\nμ is the mean of the data points.",
	},
	{
		title: "Cooling Degree Days (CDD)",
		content:
			"Indicate increase/decrease of energy required for cooling, and are calculated based on how much a day's average temperature is above 24°C (base temperature taken for tropical climates, for cooler climates - 65°F). Projected CDDs indicate the extent of additional days of cooling that may be required as compared to the long term historical average.",
	},
	{
		title: "Drought",
		content:
			"Drought is a prolonged period of abnormally low rainfall, leading to a shortage of water. The Standardized Precipitation-Evapotranspiration Index (SPEI) is a widely used metric for characterizing drought. It combines the effects of precipitation and temperature (potential evapotranspiration (PET)) to provide a comprehensive measure of drought conditions. We have calculated 12-month normalized SPEI values for drought. The SPEI drought indicator here, is a normalized value between 1 and 100.",
	},
	{
		title: "Expected annual damage (EAD)",
		content:
			"The average flood damage over many years, calculated using information from multiple potential flood events. Used as a measure of flood risk.",
	},
	{
		title: "Exposure",
		content:
			"Exposure refers to the presence of people, ecosystems, infrastructure, or economic activities in places that could be adversely affected by climate-related hazards. With respect to financial services, the term exposure would mean the value of an individual asset as well as the value of the aggregated portfolio of assets that is at risk due to physical climate risk hazards.",
	},
	{
		title: "Extreme Heat Days (90 percentile)",
		content:
			"The number of days in a year when the daily maximum temperature exceeds the 90th percentile of the long-term maximum temperature distribution. These are considered very hot days, representing an increase in heat extremes beyond typical climatic conditions.",
	},
	{
		title: "Extreme Precipitation Days (95 percentile)",
		content:
			"The number of days in a year where daily precipitation exceeds the 95th percentile of the long-term daily precipitation distribution. It identifies unusually heavy rainfall days, signaling an increase in extreme wet events compared to the historical norm.",
	},
	{
		title: "Flood Exceedance Probability",
		content:
			"The probability of a flood occurring in any given year is 1/return period, hence 1/100 for a 100-year flood, 1/50 for a 50-year flood etc.",
	},
	{
		title: "Flood Return Period",
		content:
			"Flood return period analysis is a statistical method that estimates the average time between flood events, and is used to measure the likelihood of a flood occurring at a certain intensity within a given time frame.",
	},
	{
		title: "Fluvial Floods",
		content:
			"A fluvial flood, also known as a river flood, occurs when a river or stream overflows its banks due to excessive rainfall or snowmelt upstream, leading to water spilling into surrounding areas. Typically caused by prolonged or intense precipitation over a watershed, causing water levels in rivers to rise and inundate nearby land, this metric indicates a normalized value basis the mean flood-depth of a grid of X square kilometres around a given location.",
	},
	{
		title: "GCM (General Circulation Model)",
		content:
			"Type of climate model that represents the physical processes in the atmosphere, oceans, and land surface on a global scale. These models are used to simulate the Earth's climate system and project future climate changes based on different greenhouse gas concentration scenarios.",
	},
	{
		title: "Heat Index (days)",
		content:
			"The number of days in a year when the Heat Index—a measure combining temperature and humidity to reflect perceived heat stress exceeds 35°C. The heat index highlights the severity of heatwaves, particularly considering the physiological impact on humans.",
	},
	{
		title: "Heat Stress",
		content:
			"This is a normalized indicator that depicts the combined effect of humidity and temperature. It is calculated by averaging the normalized Heat Index Days and normalized Extreme Heat Days (90 percentile) and ranges between 1 and 100. It is classified as Low (1-20), Medium (21-40), Medium-high (41-60), High (61-80), Very High (81-100).",
	},
	{
		title: "Loss of Workability (LWA)",
		content:
			"Heat exposure index that measures the effects of temperature, humidity, and other environmental factors on humans’ work capacity. It is measured in 1-100 scores, 100 indicating >80% loss of working ability.",
	},
	{
		title: "Max 1 day rain",
		content:
			"The maximum precipitation recorded in a single day (24-hour period) within a given year. It is an indicator of extreme rainfall events and is used to assess the risk of flash floods or short-term water accumulation.",
	},
	{
		title: "Max 5 day rain",
		content:
			"The maximum total precipitation accumulated over any consecutive 5-day period within a year. This metric is used to capture longer-duration extreme rainfall events, which can lead to riverine flooding and prolonged waterlogging.",
	},
	{
		title: "Pluvial Floods",
		content:
			"A pluvial flood occurs when an extreme rainfall event creates flooding independent of an overflowing water body. This type of flood can happen in any location, urban or rural, even in areas with no nearby rivers or lakes. Pluvial flooding typically results from; Surface Water Flooding: When heavy rainfall overwhelms urban drainage systems, causing water to flow into streets and nearby structures. Flash Flooding: When intense rainfall leads to rapid flooding, often in areas with poor drainage or impermeable surfaces. This metric indicates a normalized value basis the mean flood depth of a grid of X square kilometres around a given location for a 1-in-100 return period coastal flood assuming no flood protection measures.",
	},
	{
		title: "Representative Concentration Pathway (RCP)",
		content:
			"These are scenarios based on greenhouse gas concentrations in the atmosphere. There are four RCPs, each representing a different level of future warming.-RCP2.6: Assumes aggressive climate action, leading to the lowest warming (~1.5-2°C by 2100). RCP4.5: A scenario with moderate climate action, limiting warming to ~2-3°C by 2100. RCP6.0: A higher emissions scenario with less action, leading to warming of ~3-4°C. RCP8.5: The “business-as-usual” scenario, with the highest emissions and global warming over 4°C by 2100.The increase in warming is with respect to mean global temperatures in the pre-industrial era.",
	},
	{
		title: "Shared Socioeconomic Pathways (SSPs)",
		content:
			"There are five SSPs that outline different global futures based on economic growth, population, energy use, and policy choices. They are often paired with RCP-like numbers to describe emission trajectories; SSP1 (Sustainability): Focuses on sustainability and environmental protection (linked with low emissions like RCP2.6). SSP2 (Middle of the Road): A moderate pathway with current trends continuing (linked with RCP4.5). SSP3 (Regional Rivalry): A fragmented world with less global cooperation and higher emissions. SSP4 (Inequality): A world with high inequality between countries, leading to diverse emission levels.SSP5 (Fossil-fuelled Development): High economic growth and fossil fuel use (linked with RCP8.5, high emissions).These scenarios help explore how different future pathways may affect global temperature, sea levels, and climate patterns.",
	},
	{
		title: "Socio-economic climate vulnerability",
		content:
			"Socio- economic climate vulnerability focuses on the combined effects of climate change, socio-economic factors, and environmental stressors. The composite vulnerability index is created by integrating various socio-economic indicators across multiple sensitivity, and adaptive capacity like Socio-economic and livelihood-based indicators: Percentage of BPL population in rural areas, women’s participation in the workforce and the female literacy rate etc. Biophysical indicators: The yield variability of food grains, the proportion of area under rainfed agriculture and the forest area per 100 rural population etc.Institution and infrastructure related indicators: Road density, the health infrastructure per 1,000 population, and the percentage of households with an improved drinking water source etc.",
	},
	{
		title: "SSP2-4.5",
		content:
			"This scenario combines the “Middle of the Road” socioeconomic pathway (SSP2) with a stabilization pathway where radiative forcing reaches 4.5 W/m² by 2100 (RCP 4.5).",
	},
	{
		title: "SSP5-8.5",
		content:
			"This scenario combines the “Fossil-fuelled Development” socioeconomic pathway (SSP5) with a high emissions pathway where radiative forcing reaches 8.5 W/m² by 2100 (RCP 8.5).",
	},
	{
		title: "Standard Deviation",
		content:
			"Standard Deviation (SD) measures the dispersion or spread of a set of data points relative to their mean (average). In simpler terms, it tells us how much the values in a dataset deviate from the mean. A low standard deviation means the values are close to the mean, whereas a high standard deviation indicates a wider range of values.\nN is the number of data points.\nxi is each individual data point.\nμ is the mean of the data points.",
		image: (
			<p>
				SD =<span className="text-2xl">&#8730;</span>
				<span className="border-black border-t-2 -ml-[3px] pl-1 pt-[2px]">
					<sup>1</sup>/<sub>N</sub> &#8721;
					<sub>i=1</sub>
					<sup>N</sup> (x<sub>i</sub> - &#956;)
					<sup>2</sup>
				</span>
			</p>
		),
		imageIndex: 1,
	},
	{
		title: "Vulnerability",
		content:
			"Susceptibility of a system (people, ecosystems, or infrastructure) to be harmed by climate hazards, based on its capacity to cope, adapt, and recover. Vulnerability is shaped by sectoral profile, physical conditions (i.e. age) of a building, socioeconomic conditions, governance, resources, and adaptive capacity. ",
	},
	{
		title: "Water Stress",
		content:
			"Water stress is defined as the ratio of total water withdrawals to available renewable surface and groundwater supplies. This measure indicates the competition over local water resources. The smaller the gap between supply and demand, the more vulnerable a place is to water shortages. The water stress indicator here, is a normalized value between 1 and 100.",
	},
];
