import React from "react";
import { collapseData } from "../../../../constants/perilDefinitions";

const PerilDefinitionsModal = ({ isSelected, setIsSelected }) => {
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={isSelected}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal text-black"
			>
				<div className="relative p-4 w-full max-w-7xl h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">Glossary</h3>
							<button
								type="button"
								className="z-30 absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setIsSelected(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>
						<div className="overflow-y-auto max-h-[80vh]">
							{collapseData.map((data) => (
								<div className="collapse collapse-arrow bg-gray-200 rounded-xl mx-8 my-4">
									<input type="checkbox" />
									<div className="collapse-title text-lg font-medium">
										{data?.title}
									</div>
									<div className="collapse-content bg-gray-100">
										{data?.image ? (
											data?.content
												.split("\n")
												.map((item, index) => (
													<div key={index}>
														{data?.imageIndex ===
															index && (
															<div className="text-center">
																{data?.image}
															</div>
														)}
														<p className="pt-2">
															{item}
														</p>
													</div>
												))
										) : (
											<p className="pt-2 whitespace-pre-wrap">
												{data?.content}
											</p>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PerilDefinitionsModal;
