export const projectedRiskOptions = [
	{
		label: "SSP2-4.5",
		value: "SSP2-4.5",
	},
	{
		label: "SSP5-8.5",
		value: "SSP5-8.5",
	},
];
