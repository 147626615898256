export const CURRENT_TAB_NAMES = {
	ClimateHazardScore: "Climate Hazard Score",
	PotentialEconomicLoss: "Potential Economic Loss",
	CFRScore: "Climate Financial Risk Score",
};

export const FUTURE_TAB_NAMES = {
	ClimateHazardScore: "Climate Hazard Score",
	CFRScore: "Climate Financial Risk Score",
	CFI: "Climate Financial Impact",
};
