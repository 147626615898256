import { captureException } from "@sentry/react";
import axiosHttpService from "../axioscall";

export const processPetFuture = async (
	email,
	location,
	coordinate,
	scenario
) => {
	if (!email || !location || !coordinate || !scenario) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/process-pet`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
				scenario: scenario,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processSpeiFuture = async (
	email,
	location,
	coordinate,
	scenario
) => {
	if (!email || !location || !coordinate || !scenario) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/process-spei`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
				scenario: scenario,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processHeatStressFuture = async (
	email,
	location,
	coordinate,
	scenario
) => {
	if (!email || !location || !coordinate || !scenario) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/process-heat-stress`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
				scenario: scenario,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processExtremePrecipitationFuture = async (
	email,
	location,
	coordinate,
	scenario
) => {
	if (!email || !location || !coordinate || !scenario) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/process-ep`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
				scenario: scenario,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processWaterStressFuture = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/process-water-stress`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};
