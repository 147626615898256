import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import LogoImage from "../assets/logo.png";
import { signInWithEmail } from "../services/BackendConnectors/userLogin";
import { LoginContext } from "../LoginProvider/LoginProvider";
import { useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import ForgotPasswordModal from "../components/Modal/ForgotPasswordModal";
import SuccessModal from "../components/Modal/SuccessModal";
import ErrorModal from "../components/Modal/ErrorModal";
import { Role } from "../constants/Role";
import QuoteCard from "../components/Quote/QuoteCard";

const LoginPage = () => {
	const path = useNavigate();
	const { login } = useContext(LoginContext);
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [loginResponse, setloginResponse] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});
	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = "Email is required";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
			) {
				errors.email = "Invalid email address";
			}

			if (!values.password) {
				errors.password = "Password is required";
			}

			return errors;
		},
		onSubmit: async (values) => {
			// Handle login logic here
			setisSubmit(true);

			try {
				const loginData = await signInWithEmail(
					values.email,
					values.password
				);

				if (loginData) {
					login(loginData);
					if (loginData.role === Role.Admin) {
						path("/admin");
					} else if (loginData.role === Role.User) {
						path("/user");
					}
				}
			} catch (error) {
				captureException(error);
				setloginResponse({
					status: false,
					msg: "Invalid email or password...",
				});
			} finally {
				setisSubmit(false);
			}
		},
	});
	useEffect(() => {
		if (formik.values.email && formik.values.password) {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}

		if (isSubmit) {
			setIsDisable(true);
		}
	}, [formik.values.email, formik.values.password, isSubmit]);
	return (
		<div className="h-screen flex flex-col">
			{isSelected && (
				<ForgotPasswordModal
					isSelected={isSelected}
					setIsSelected={setIsSelected}
					setSuccessmsg={setSuccessmsg}
					setErrormsg={setErrormsg}
				/>
			)}
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			<div className="px-2 sm:px-4 md:px-6 py-2 items-center bg-transparent  text-neutral-700">
				<img
					className="md:w-44 h-11 w-32 md:h-14 cursor-pointer"
					src={LogoImage}
					alt="company logo"
				/>
			</div>
			<div className="w-full bg-slate-100 h-full flex items-center justify-center">
				<div className="flex justify-center items-center gap-x-8">
					<div className="md:w-[60rem] 2xl:w-[65rem] flex justify-end overflow-hidden rounded-lg h-[34rem] opacity-90 shadow-lg">
						<QuoteCard />
					</div>
					<div className="flex justify-start w-1/3">
						<div className="w-full flex justify-center bg-white rounded-lg shadow-md py-20">
							<form
								onSubmit={formik.handleSubmit}
								className="py-1"
							>
								<div className="md:gap-3 mt-6">
									<div className="w-full mb-5 md:mb-0">
										<div className="font-bold text-2xl mb-4">
											Login
										</div>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												Email
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="email"
											value={formik.values.email}
											type="text"
											placeholder="Enter Email"
											className="input input-bordered bg-lightmode-200 border-[#BBC0CC] md:w-full 2xl:w-80"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
										/>
										{formik.touched.email &&
											formik.errors.email && (
												<p
													style={{
														color: "red",
														margin: "0px",
													}}
												>
													<small>
														{formik.errors.email}
													</small>
												</p>
											)}
									</div>

									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												Password
											</span>
										</label>
										<input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name="password"
											value={formik.values.password}
											type="password"
											placeholder="Enter Password"
											className="input input-bordered bg-lightmode-200 border-[#BBC0CC] md:w-full 2xl:w-80"
											style={{
												borderWidth: "1px",
												borderRadius: "8px",
											}}
										/>
										{formik.touched.password &&
											formik.errors.password && (
												<p
													style={{
														color: "red",
														margin: "0px",
													}}
												>
													<small>
														{formik.errors.password}
													</small>
												</p>
											)}
									</div>
								</div>
								<div className="mt-2 mb-0 ml-1 ">
									<label
										className="text-sm text-[#4B74FF] cursor-pointer"
										onClick={() => {
											setIsSelected(true);
										}}
									>
										Forgot Password
									</label>
								</div>
								{loginResponse && !loginResponse?.status && (
									<p
										style={{
											color: "red",
											margin: "0px",
										}}
									>
										<small>{loginResponse.msg}</small>
									</p>
								)}
								<div className="my-6 flex  justify-around">
									<button
										disabled={
											!formik.values.email &&
											!formik.values.password
										}
										data-modal-toggle="popup-modal"
										type="submit"
										className={`${
											!isDisable
												? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
												: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
										}`}
									>
										Login
										{isSubmit && (
											<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
