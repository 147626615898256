// QuoteCard.js
import React from "react";

const QuoteCard = () => {
	return (
		<div className="flex items-center justify-center w-full h-full ">
			<div className="px-20 bg-white rounded-lg shadow-lg py-20 w-full">
				<p className="text-2xl italic text-gray-800 mb-44 pt-10 leading-relaxed">
					"The evidence is clear: the time for action is now. The
					climate time-bomb is ticking, we have the knowledge and the
					technology to tackle the climate crisis."
				</p>
				<p className="text-right text-gray-600 font-semibold pb-10 pr-4">
					- IPCC (Intergovernmental Panel on Climate Change), 2023
				</p>
			</div>
		</div>
	);
};

export default QuoteCard;
