import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Selection from "../../../../components/Inputs/Selection";
import { sortByPropertyAscending } from "../../../../services/Helpers/sortItemsHelper";
import { kycStatusOptions } from "./ModalOptions/UserModalOptions";
import { captureException } from "@sentry/react";
import { editUser } from "../../../../services/ApiCalls/userCall";

const EditUserModal = ({
	user,
	users,
	selectedEditModal,
	setSelectedEditModal,
	setErrormsg,
	setSuccessmsg,
	setUsers,
}) => {
	const [isSubmit, setisSubmit] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [loginResponse, setloginResponse] = useState(false);
	const [kycOutline, setKycOutline] = useState(false);

	const formik = useFormik({
		initialValues: {
			stateNum: user.role,
			kycStatus: user.kycStatus === undefined ? false : user.kycStatus,
		},
		validate: (values) => {
			const errors = {};

			if (values.kycStatus === undefined) {
				errors.kycStatus = "Kyc Status is required";
			}

			return errors;
		},
		onSubmit: async (values) => {
			setisSubmit(true);

			try {
				let result = await editUser(
					user.email,
					"kycStatus",
					values.kycStatus
				);

				if (result.status === "success") {
					let userData = result.data;
					let finalUserData = {
						...userData,
						kycStatus: values.kycStatus,
					};
					let finalUsers = users.map((data) =>
						data.email === userData.email ? finalUserData : data
					);
					finalUsers = finalUsers.sort(
						sortByPropertyAscending("role")
					);
					setUsers(finalUsers);
					setloginResponse({
						status: true,
						msg: "User Edited Successfully!",
					});
					setSuccessmsg({
						status: true,
						msg: "User Edited Successfully!",
					});
				} else {
					setloginResponse({
						status: false,
						msg: result?.message
							? result?.message
							: "Failed to Edit User...",
					});
					setErrormsg({
						status: true,
						msg: result?.message
							? result?.message
							: "Failed to Edit User...",
					});
				}
			} catch (error) {
				captureException(error);
				setloginResponse({
					status: false,
					msg: "Failed to Edit User...",
				});
				setErrormsg({
					status: true,
					msg: "Failed to Edit User...",
				});
			} finally {
				setSelectedEditModal(false);
				setisSubmit(false);
			}
		},
	});

	useEffect(() => {
		if (formik.values.kycStatus !== "") {
			setIsDisable(false);
		} else {
			setIsDisable(true);
		}
		if (isSubmit) {
			setIsDisable(true);
		}
	}, [isSubmit, formik.values.kycStatus]);
	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle"
				checked={selectedEditModal}
				readOnly
			/>
			<div
				style={{ backdropFilter: "brightness(100%) blur(8px)" }}
				className="modal"
			>
				<div className="relative p-4 w-full max-w-md h-full md:h-auto z-50">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex justify-between px-4 md:px-8 md:border-b md:border-neutral-300 md:dark:border-darkmode-500 mt-[4em] md:mt-0 py-4">
							<h3 className="font-normal text-xl">Edit User</h3>

							<button
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white mr-3"
								data-modal-toggle="popup-modal"
								onClick={() => {
									setSelectedEditModal(false);
								}}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>

						<div className="p-6">
							<form onSubmit={formik.handleSubmit}>
								<div className="md:gap-3">
									<div
										className={`w-full  md:mr-2 mb-5 md:mb-0`}
									>
										<label className="label text-lg">
											<span className="text-black dark:text-white">
												Kyc Status
											</span>
										</label>
										<div
											className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
												kycOutline
													? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
													: ""
											}`}
										>
											<Selection
												onChange={(value) => {
													formik.setFieldValue(
														"kycStatus",
														value.value
													);
												}}
												value={formik.values.kycStatus}
												placeholder="Enter&nbsp;Role"
												options={kycStatusOptions}
												focus={setKycOutline}
												className=" w-full bg-lightmode-200 dark:bg-darkmode-800 dark:border-[#3A3C43] border-[#BBC0CC]"
											></Selection>
										</div>
										{formik.touched.kycStatus &&
										formik.errors.kycStatus ? (
											<p style={{ color: "red" }}>
												<small>
													{formik.errors.kycStatus}
												</small>
											</p>
										) : null}
									</div>
								</div>
								{loginResponse && !loginResponse?.status && (
									<p
										style={{
											color: "red",
											margin: "0px",
										}}
									>
										<small>{loginResponse.msg}</small>
									</p>
								)}
								<div className="mt-10 flex  justify-around">
									<button
										onClick={() => {
											setSelectedEditModal(false);
										}}
										data-modal-toggle="popup-modal"
										type="button"
										className={`py-2 px-12 border-2 border-neutral-500 capitalize font-medium hover:bg-gray-50 rounded-3xl`}
									>
										Cancel
									</button>
									<button
										disabled={formik.values.stateNum === ""}
										data-modal-toggle="popup-modal"
										type="submit"
										className={`${
											!isDisable
												? "py-2 relative px-12 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
												: "py-2 relative px-12 bg-gradient-to-r bg-neutral-400 cursor-not-allowed  opacity-40 capitalize font-medium border-none rounded-3xl"
										}`}
									>
										Submit
										{isSubmit && (
											<div className="animate-spin border-solid  top-3 left-16 absolute border-[2.4px] border-t-[#14171F] border-r-[#14171F] border-[#fff] w-[1.3rem] h-[1.3rem] rounded-full"></div>
										)}
									</button>
								</div>
								<div>
									{loginResponse && loginResponse?.status && (
										<p
											style={{
												color: "green",
												margin: "0px",
											}}
										>
											<small>{loginResponse.msg}</small>
										</p>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditUserModal;
